import { MultiUserAvatar, UserAvatar101 as UserAvatar } from 'src/components/avatars';
import { AvatarListItem, IconListItem } from 'src/components/list-item';
import { DocumentListModel } from '../../models';

interface Props {
  document: DocumentListModel;
  forceDisable?: boolean;
  className?: string;
  dataTestId?: string;
}

function DocumentItem({ document, className, forceDisable, dataTestId }: Props) {
  const { name, icon, linkTo, description, participants, disabled, selected } = document;

  return (
    <IconListItem
      className={className}
      selected={selected}
      icon={icon}
      linkTo={linkTo}
      disabled={forceDisable || disabled}
      primary={name}
      secondary={description}
      secondaryAction={<MultiUserAvatar items={participants} placement="right-start" />}
      dataTestId={dataTestId}
    >
      {participants &&
        participants.length > 1 &&
        participants?.map((participant, index) => {
          const { name, avatarUrl, role, badge } = participant;
          return (
            <AvatarListItem //
              key={`${name}-${index}`}
              avatar={<UserAvatar src={avatarUrl} text={name} size="extraSmall" placement="right-start" tooltipType="document" />}
              avatarSize="s"
              primary={role}
              secondary={badge}
            />
          );
        })}
    </IconListItem>
  );
}

export default DocumentItem;
