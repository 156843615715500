export enum FeatureToggleEnum {
  none = 'None',
  additionalTitlesEnabled = 'AdditionalTitlesEnabled',
  bIReporting = 'BIReporting',
  biReportingSchedulerFavoriteEnabled = 'BiReporting_Schedule_Favorite_Feature_Enabled',
  defaultFieldPopulationEnabled = 'DefaultFieldPopulationEnabled',
  differentiateIntegratedWorkspaces = 'DifferentiateIntegratedWorkspaces',
  // this is only enabled for some test env and only for fsm testing only
  dischargeMortgageeSourceFundEnabled = 'DischargeMortgageeSourceFundEnabled',
  documentAttachmentsEnhancementEnabled = 'DocumentAttachmentsEnhancementEnabled',
  financialSettlementsEnabled = 'FinancialSettlementsEnabled',
  groupDocumentWorkflowEnabled = 'GroupDocumentWorkflowEnabled',
  interoperableWorkspaceIdEnabled = 'IOPWorkspaceIdEnabled',
  reap = 'REAP',
  restrictManualAccountDetailsEntry = 'RestrictManualAccountDetailsEntry',
  revokeSettlementDateApproval = 'RevokeSettlementDateApproval',
  signingLimitsEnabled = 'SigningLimitsEnabled',
  transferEAP = 'TransferEAP',
  trustAccounts = 'TrustAccounts',
  financialSettlementScheduleMFAEnabled = 'FinancialSettlementScheduleMFAEnabled',
  editUserEmail = 'EditUserEmail',
  settlementDateOnlyEnabled = 'SettlementDateOnlyEnabled',
  settlementNullDateTimeEnabled = 'SettlementNullDateTimeEnabled',
  fssLineItemSaveEnabled = 'FssLineItemSaveEnabled',
  breakdownLineItems = 'BreakdownLineItems',
  workspaceMessenger101Enabled = 'WorkspaceMessenger1.1Enabled',
  mlcEnabled = 'MLCEnabled',
  searchAndJoinWorkspaceEnabled = 'SearchAndJoinWorkspaceEnabled',
  forwardInvitationToSubscriberEnabled = 'ForwardInvitationToSubscriberEnabled',
  showForeignTimeZoneInUserProfile = 'ShowForeignTimeZoneInUserProfile',
  reportSubTypesUIEnabled = 'ReportSubTypesUIEnabled',
  transactionTypeEnabled = 'TransactionTypeEnabled',
  criticalRoles = 'CriticalRoles',
  criticalRolesNSW = 'CriticalRolesNSW'
}
