import { DocumentTypeIdentifierEnum } from '@sympli/api-gateway/enums';
import { LookupItemModel } from '@sympli/ui-framework/models';

import { GroupedOptions } from 'src/components/multiple-select-checkbox/MultipleSelectCheckbox';
import { DocumentFormIdentifierDisplayMapping } from 'src/models/document';

// Workspace Status
export enum WorkspaceStatusEnum {
  InPreparation = 0,
  InPreparationWithErrors = 1,
  Ready = 2,
  Settling = 3,
  SettlingWithErrors = 4,
  Settled = 5,
  Archived = 6,
  Withdrawn = 7,
  Abandoned = 8,
  Queued = 9,
  Lodging = 10,
  Lodged = 11,
  Completed = 12
}

export const WorkspaceStatusOptions: LookupItemModel<number>[] = [
  {
    id: WorkspaceStatusEnum.InPreparation,
    name: 'In Preparation'
  },
  {
    id: WorkspaceStatusEnum.InPreparationWithErrors,
    name: 'In Preparation (Errors)'
  },
  {
    id: WorkspaceStatusEnum.Ready,
    name: 'Ready'
  },
  {
    id: WorkspaceStatusEnum.Queued,
    name: 'Queued'
  },
  {
    id: WorkspaceStatusEnum.Lodging,
    name: 'Lodging'
  },
  {
    id: WorkspaceStatusEnum.Lodged,
    name: 'Lodged'
  },
  {
    id: WorkspaceStatusEnum.Completed,
    name: 'Completed'
  }
];

export const WorkspaceArchivedStatusOptions: LookupItemModel<number>[] = [
  {
    id: WorkspaceStatusEnum.Archived,
    name: 'Archived'
  },
  {
    id: WorkspaceStatusEnum.Abandoned,
    name: 'Abandoned'
  }
];

// my tasks
export enum MyTaskEnum {
  CompleteDocuments = 0,
  ReviewDocuments = 1,
  SignDocuments = 2
}

export const MyTaskGroupOptions: GroupedOptions<number>[] = [
  {
    title: 'Documents',
    options: [
      {
        id: MyTaskEnum.CompleteDocuments,
        name: 'Complete'
      },
      {
        id: MyTaskEnum.ReviewDocuments,
        name: 'Review'
      },
      {
        id: MyTaskEnum.SignDocuments,
        name: 'Sign'
      }
    ]
  }
];

// Document type
export const DocumentTypeOptions: LookupItemModel<number>[] = Object.entries(DocumentFormIdentifierDisplayMapping).map(([id, name]) => ({ id: parseInt(id), name }));

// Document types not show in the Standalone Dashboard
export const DocumentTypesNotInStandalone = [
  DocumentTypeIdentifierEnum.LodgementInstructions,
  DocumentTypeIdentifierEnum.CoRDHolderConsent,
  DocumentTypeIdentifierEnum.SettlementStatement,
  DocumentTypeIdentifierEnum.Nomination,
  DocumentTypeIdentifierEnum.WithdrawalCoRDHolderConsent,
  DocumentTypeIdentifierEnum.PaymentSummary,
  DocumentTypeIdentifierEnum.AdministrativeNotice,
  DocumentTypeIdentifierEnum.TransferOfControl,
  DocumentTypeIdentifierEnum.NoticeOfAcquisition,
  DocumentTypeIdentifierEnum.Form24PartA,
  DocumentTypeIdentifierEnum.Form24PartB,
  DocumentTypeIdentifierEnum.NationalMortgageOverInterestInLand,
  DocumentTypeIdentifierEnum.TransmissionApplicationByBeneficiaryDeviseeNextOfKin,
  DocumentTypeIdentifierEnum.TransmissionApplicationByExecutorAdministratorTrustee,
  DocumentTypeIdentifierEnum.DischargeOfMortgageOverInterestInLand,
  DocumentTypeIdentifierEnum.TransferOfInterest,
  DocumentTypeIdentifierEnum.CaveatOverInterestInLand,
  DocumentTypeIdentifierEnum.NoticeOfSale,
  DocumentTypeIdentifierEnum.Transfer
];

// Document Status
export enum DocumentsStatusEnum {
  InPreparation = 0,
  Prepared = 1,
  InPreparationWithErrors = 2,
  Ready = 3,
  Lodging = 4,
  Lodged = 5,
  Completed = 6
}

export const DocumentsStatusOptions: LookupItemModel<number>[] = [
  {
    id: DocumentsStatusEnum.InPreparation,
    name: 'In Preparation'
  },
  {
    id: DocumentsStatusEnum.InPreparationWithErrors,
    name: 'In Preparation (Errors)'
  },
  {
    id: DocumentsStatusEnum.Prepared,
    name: 'Prepared'
  },
  {
    id: DocumentsStatusEnum.Ready,
    name: 'Ready'
  },
  {
    id: DocumentsStatusEnum.Lodging,
    name: 'Lodging'
  },
  {
    id: DocumentsStatusEnum.Lodged,
    name: 'Lodged'
  },
  {
    id: DocumentsStatusEnum.Completed,
    name: 'Completed'
  }
];

export enum FilterEnum {
  AccessedDate = 0, // only shown up in the Recent tab
  CompletedDate = 1,
  ArchivedDate = 2,
  Jurisdiction = 3,
  MyTasks = 4,
  DocumentType = 5,
  DocumentStatus = 6,
  WorkspaceStatus = 7,
  Groups = 8,
  Members = 9
}

export const FilterOptions: LookupItemModel<number>[] = [
  {
    id: FilterEnum.AccessedDate,
    name: 'Last Accessed Date'
  },
  {
    id: FilterEnum.Jurisdiction,
    name: 'Jurisdiction (JDX)'
  },
  {
    id: FilterEnum.MyTasks,
    name: 'My Tasks'
  },
  {
    id: FilterEnum.DocumentType,
    name: 'Document Type'
  },
  {
    id: FilterEnum.DocumentStatus,
    name: 'Document Status'
  },
  {
    id: FilterEnum.WorkspaceStatus,
    name: 'Workspace Status'
  }
];
