import * as React from 'react';

import classNames from 'classnames';
import { NavigateFunction } from 'react-router-dom';
import { Action } from 'redux';
import Badge from '@mui/material/Badge';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import {
  ComplianceLevelEnum,
  DocumentStatusEnum,
  JurisdictionsEnum,
  LodgementCaseStatusEnum,
  ParticipantStatusEnum,
  ReadyForSettlementFailureCodeEnum,
  SettleSmartStatusEnum,
  UserLevelPermissionEnum,
  WorkspaceRoleEnum,
  WorkspaceStatusEnum,
  WorkspaceTypeEnum
} from '@sympli/api-gateway/enums';
import { WorkspaceParticipantApiResponse, WorkspaceReportApiResponse } from '@sympli/api-gateway/models';
import { ExpectedSettlementDate, LodgementCase } from '@sympli/api-gateway/shared';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { MessageNotificationContainer, MessageNotificationWithLocalStorage } from '@sympli/ui-framework/components/message-notification';
import { MessageModel } from '@sympli/ui-framework/components/message-notification/components/message-grid';

import { Box } from 'src/components/layout';
import { LineLoader, ListLoader } from 'src/components/loaders';
import Secured from 'src/containers/shared/auth/profile-security/Secured';
import { getDefaultLodgementCase, resolveDefaultLodgementCaseStatus, resolveSecondaryLodgementCases } from 'src/containers/shared/helper';
import { LinkedWorkspaceApiResponseModel } from 'src/containers/shared/linked-workspace-list/models';
import ParticipantList from 'src/containers/shared/participant-list';
import SettlementDateTimeCard from 'src/containers/shared/settlement-date-time-card';
import { WorkspaceNotificationModel } from 'src/containers/workspace/financial/detail/components/error-message-panel/models';
import LodgementErrorMessageNotificationContainer from 'src/containers/workspace/shared/components/lodgement-error-message-notification';
import StatusHeader from 'src/containers/workspace/shared/detail/components/status-header';
import TabLogsContainer from 'src/containers/workspace/shared/detail/components/tab-logs';
import { convertComplianceToMessageGridModel, resolveBackLink } from 'src/containers/workspace/shared/detail/helpers';
import { WorkspaceIssuesModel } from 'src/containers/workspace/shared/detail/selectors';
import WorkspacePageContentWrapper from 'src/containers/workspace/shared/WorkspacePageContentWrapper';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import { SettlementDateTimeModel } from 'src/models';
import ManageWorkspaceBoxContainer from '../../shared/components/manage-workspace/ManageWorkspaceBoxContainer';
import OffPlatformTasksContainer from '../../shared/components/off-platform-tasks-container';
import TabLinkedWorkspaces from '../../shared/components/tab-linked-workspaces';
import WorkspaceFileDeleteDialog from '../../shared/components/workspace-file-delete-dialog';
import WorkspaceFileUploadDialog from '../../shared/components/workspace-file-upload-dialog';
import WorkspaceFilesContainer from '../../shared/components/workspace-files-container';
import { WorkspaceFile } from '../../shared/components/workspace-files/models';
import { actionFetchAdditionalInvitableRoles } from '../../shared/detail/actions';
import WorkspaceReport from '../../shared/detail/components/tab-workspace-report';
import { SettlementStepEnum } from '../../shared/detail/models';
import { WorkspaceTasksState } from '../../shared/detail/reducers/workspaceTasks';
import { isTaskAccessible } from '../helpers';
import { resolveSettlementDateLink } from '../settlement-date/helper';
import { RescindSettlementDateTimeModel } from '../settlement-date/models';
import InviteAdditionalParticipantsContainer from './components/invite-additional-participants/InviteAdditionalParticipantsContainer';
import TabTasksContainer from './components/tab-tasks';
import TabTasksV2 from './components/tab-tasks/v2/TabTasks';
import {
  resolveWorkspaceProgressNotificationPanelTypeForPLC,
  resolveWorkspaceProgressNotificationPanelTypeForSLC,
  WorkspaceProgressNotificationPanel,
  WorkspaceProgressNotificationPanelBackground,
  WorkspaceProgressPanelNotificationTypeEnum
} from './components/workspace-progress-notification-panel';
import styles, { ClassKeys } from './styles';

export interface FinancialDetailProps {
  workspaceId: string;
  participantId: string;
  navigate: NavigateFunction;
  // loader
  isLoadingWorkspaceBasicInfo: boolean;
  isLoadingWorkspaceDetail: boolean;
  isLoadingWorkspaceParticipants: boolean;
  isLoadingWorkspaceTasks: boolean;
  isLoadingSettlementDateDetails: boolean;

  // data
  workspaceDetail: {
    // basic workspace info
    isLocked?: boolean;
    isRollover?: boolean;
    jurisdictionId?: JurisdictionsEnum;
    workspaceStatusId?: WorkspaceStatusEnum;
    workspaceTypeId?: WorkspaceTypeEnum;
    settlementDate?: SettlementDateTimeModel;
    expectedSettlementDate?: ExpectedSettlementDate;
    isInteroperable?: boolean;
    lodgementCases?: LodgementCase[];
    // full workspace info
    settlementStatusId?: SettleSmartStatusEnum;
    lastSettlementReadyCheckReasonId?: ReadyForSettlementFailureCodeEnum;
  };

  // current participant info
  currentParticipant: {
    reference?: string;
    workspaceRoleId?: WorkspaceRoleEnum;
    groupId?: string;
  };

  distributionsStatus?: DocumentStatusEnum;

  // settlement date info
  settlementDateDetail: {
    // list of participants that accepted settlement
    acceptedByParticipants?: string[];
    isAcceptedByUser: boolean;
    isProposedByUser: boolean;
    isAcceptedByAll: boolean;
    localProposedSettlementDate?: string;
    proposedSettlementDate?: string;
    settlementDateNotifications?: RescindSettlementDateTimeModel[];
    settlementAwaitingAcceptanceCount: number;
    isUnsupported?: boolean;
  };

  // settlement transaction info
  latestSettlementStep?: SettlementStepEnum;
  // list of current workspace participants
  workspaceParticipants: WorkspaceParticipantApiResponse[];
  // list of withdrawn archived workspace participants
  withdrawnParticipants: WorkspaceParticipantApiResponse[];
  // list of linked workspaces
  linkedWorkspaces: LinkedWorkspaceApiResponseModel[];
  // list of reports
  workspaceReports: WorkspaceReportApiResponse[];
  // list of tasks for current participant
  workspaceTasksState: WorkspaceTasksState;
  // profile data
  profile: {
    userId: string;
    subscriberId: string;
  };

  workspaceIssues: WorkspaceIssuesModel;

  isAbandonedOrWithdrawnWorkspace: boolean;
  lodgementCaseIdToDocumentNameMap: Record<string, string>;

  dispatch: SafeDispatch<Action>;
  onRefreshTasks(): void;
  onAcceptSettlementClick(): void;

  // feature flags
  isMLCEnabled?: boolean;
}

type Props = FinancialDetailProps & WithStyles<ClassKeys>;

enum TabEnum {
  Tasks = 0,
  Logs = 1,
  Reports = 2,
  LinkedWorkspaces = 3
}

interface State {
  selectedTab: TabEnum;
  openInviteParticipantDialog: boolean;
  openFileUploadDialog: boolean;
  openDeleteFileDialog: boolean;
  selectedFile: WorkspaceFile | null;
}

class FinancialDetail extends React.PureComponent<Props, State> {
  public readonly state: Readonly<State> = {
    selectedTab: TabEnum.Tasks,
    openInviteParticipantDialog: false,
    openFileUploadDialog: false,
    openDeleteFileDialog: false,
    selectedFile: null
  };

  static getDerivedStateFromProps(props, state) {
    // Any time the current user changes,
    // Reset any parts of state that are tied to that user.
    // In this simple example, that's just the email.
    if (props.isAbandonedOrWithdrawnWorkspace && state.selectedTab !== TabEnum.Logs) {
      return {
        selectedTab: TabEnum.Logs
      };
    }
    return null;
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    if (prevState.selectedTab !== this.state.selectedTab && this.state.selectedTab === TabEnum.Tasks) {
      this.props.onRefreshTasks();
    }
  }

  render() {
    return (
      <>
        {this.renderMessages()}
        {this.renderSettlementDateNotifications()}
        {this.state.selectedTab === TabEnum.Tasks && this.renderWithdrawnNotifications()}
        <WorkspacePageContentWrapper>
          {this.renderMatterHeader()}
          {this.renderInviteInWorkspaceParticipants()}
          {this.renderFileUpload()}
          {this.renderDeleteFile()}
          {this.renderTabsNavigation()}
          {this.renderSelectedTab()}
        </WorkspacePageContentWrapper>
      </>
    );
  }

  private renderSettlementDateNotifications() {
    const { settlementDateDetail, workspaceId, participantId, workspaceParticipants } = this.props;
    const proposedSettlementDate = settlementDateDetail.proposedSettlementDate;

    if (workspaceParticipants.length === 0 || proposedSettlementDate === undefined) {
      return;
    }

    return (
      <MessageNotificationContainer>
        {settlementDateDetail.settlementDateNotifications?.map(value => {
          // we need to show information related to the participant that revoked the settlement date
          const rescinder = workspaceParticipants.find(x => x.id === value.participantId);
          if (!rescinder) return null; // rescinder might be missing because participant withdraws from workspace
          return value.participantId === participantId ? null : (
            <MessageNotificationWithLocalStorage
              storageNamespace={`${workspaceId}/${value.participantId}/${proposedSettlementDate}/revokeSettlementDate`}
              messageId={`${value.id}`}
              variant="warning"
              primary={`${rescinder.workspaceRole.name} - ${rescinder.name} has unaccepted the settlement date and time.`}
              key={`${value.participantId}-${value.id}`}
            />
          );
        })}
        {settlementDateDetail.isUnsupported && (
          <MessageNotificationWithLocalStorage
            storageNamespace={`${workspaceId}/${participantId}/${proposedSettlementDate}/unSupported`}
            messageId={`${participantId}-${proposedSettlementDate}`}
            variant="warning"
            primary="A participating ELN has advised that the proposed SDT can no longer be supported as a valid settlement date and time. Please consider another date to ensure date acceptance and settlement"
            key={`${participantId}-${proposedSettlementDate}`}
          />
        )}
      </MessageNotificationContainer>
    );
  }

  private renderMessages = () => {
    const {
      workspaceId,
      participantId,
      workspaceIssues: {
        //
        lodgementCompliances,
        settlementErrorMessages
      }
    } = this.props;

    // MR - TODO memoize this
    const errors: MessageModel[] = lodgementCompliances?.filter(m => m.complianceLevel === ComplianceLevelEnum.Error).map(convertComplianceToMessageGridModel) || [];
    const warnings: MessageModel[] = lodgementCompliances?.filter(m => m.complianceLevel === ComplianceLevelEnum.Warning).map(convertComplianceToMessageGridModel) || [];
    const lodgementComplianceId = lodgementCompliances?.length ? lodgementCompliances[0].complianceId : undefined;

    const workspaceErrors: WorkspaceNotificationModel = {
      alertType: settlementErrorMessages?.alertType,
      alertHeader: settlementErrorMessages?.alertHeader,
      messages: settlementErrorMessages?.errors.filter(m => m.level === ComplianceLevelEnum.Error).map(m => m.message),
      notificationId: settlementErrorMessages?.dateCreated.valueOf().toString()
    };

    return (
      <LodgementErrorMessageNotificationContainer //
        participantId={participantId}
        workspaceId={workspaceId}
        workspaceErrors={workspaceErrors}
        errors={errors}
        warnings={warnings}
        lodgementComplianceId={lodgementComplianceId}
      />
    );
  };

  private renderWithdrawnNotifications() {
    const { participantId, workspaceParticipants, withdrawnParticipants } = this.props;

    const items = workspaceParticipants.filter(p => p.participantStatus.id === ParticipantStatusEnum.Accepted);

    const currentParticipant = items.find(x => x.id === participantId);
    if (!currentParticipant?.withdrawnParticipantIdsAfterAcceptedInvite?.length) {
      return null;
    }

    const visibleWithdrawnParticipants = withdrawnParticipants.filter(participant => currentParticipant!.withdrawnParticipantIdsAfterAcceptedInvite!.includes(participant.id));

    return <MessageNotificationContainer>{visibleWithdrawnParticipants.map(x => this.participantArchivedNotification(x.workspaceRole.name, x.id))}</MessageNotificationContainer>;
  }

  private participantArchivedNotification(role: string, id: string) {
    const { workspaceId, participantId } = this.props;

    return (
      <MessageNotificationWithLocalStorage
        key={id}
        v2
        storageNamespace={`${workspaceId}/${participantId}/withdrawParticipantWarning`}
        messageId={id}
        open={undefined}
        variant="warning"
        primary={
          <div>
            <strong>'{role}' has withdrawn from workspace.</strong> This action may have caused changes to your workspace, please check your tasks.
          </div>
        }
      />
    );
  }

  private renderMatterHeader() {
    const {
      //
      classes,
      isLoadingWorkspaceBasicInfo: isLoadingWorkspaceDetail,
      isLoadingWorkspaceParticipants,
      workspaceDetail,
      currentParticipant,
      distributionsStatus,
      workspaceId,
      participantId,
      dispatch
    } = this.props;

    if (isLoadingWorkspaceDetail || isLoadingWorkspaceParticipants) {
      return (
        <div className={classes.headerLoaderContainer}>
          <LineLoader color="white" variant="small" widthPercent={100} classes={{ root: classes.headerLoader }} />
        </div>
      );
    }

    if (!(Number.isInteger(currentParticipant.workspaceRoleId) && Number.isInteger(workspaceDetail.workspaceStatusId))) {
      return null;
    }

    return (
      <StatusHeader
        // route params
        workspaceId={workspaceId}
        participantId={participantId}
        // basic workspace info
        isLocked={workspaceDetail.isLocked}
        workspaceStatus={workspaceDetail.workspaceStatusId!}
        // current participant
        reference={currentParticipant.reference}
        dispatch={dispatch}
        // other

        distributionsStatus={distributionsStatus}
      />
    );
  }

  private renderTabsNavigation() {
    const { classes, workspaceReports, isAbandonedOrWithdrawnWorkspace, workspaceTasksState } = this.props;

    if (isAbandonedOrWithdrawnWorkspace) {
      return (
        <Tabs value={TabEnum.Logs} onChange={this.handleTabChange} indicatorColor="primary" textColor="inherit">
          <Tab value={TabEnum.Logs} label="Log" data-testid="workspace-tab-log" />
        </Tabs>
      );
    }

    return (
      <Tabs value={this.state.selectedTab} onChange={this.handleTabChange} indicatorColor="primary" textColor="inherit">
        {
          <Tab
            classes={{ root: classes.tab }}
            label={this.renderTaskLabel()}
            data-testid={!workspaceTasksState.items.length ? 'workspace-tab-overview' : 'workspace-tab-actions'}
          />
        }
        <Tab label="Log" classes={{ root: classes.tab }} data-testid="workspace-tab-log" />
        {workspaceReports.length ? <Tab classes={{ root: classes.tab }} data-testid="workspace-tab-reports" label={this.renderReportLabel()} /> : null}
        {Boolean(this.props.linkedWorkspaces.length) && (
          <Tab value={TabEnum.LinkedWorkspaces} label={this.renderLinkedWorkspacesLabel()} data-testid="workspace-tab-linked-workspaces" classes={{ root: classes.tab }} />
        )}
      </Tabs>
    );
  }

  // TODO make it a functional component
  private renderTaskLabel() {
    const { workspaceTasksState, classes } = this.props;

    if (!workspaceTasksState.items.length) {
      return 'Overview';
    }

    return (
      <Badge classes={{ badge: classes.badge }} badgeContent={workspaceTasksState.items.length} max={99} color="primary">
        Actions
      </Badge>
    );
  }

  private renderReportLabel() {
    const { workspaceReports, classes } = this.props;

    const reportCount = workspaceReports.length;

    if (reportCount === 0) {
      return 'Reports';
    }

    return (
      <Badge classes={{ badge: classes.badge }} badgeContent={reportCount} max={99} color="primary">
        Reports
      </Badge>
    );
  }

  private renderWorkspaceProgressNotificationPanels() {
    const {
      //
      participantId,
      workspaceDetail: {
        //
        workspaceStatusId,
        settlementStatusId,
        isRollover,
        lodgementCases,
        lastSettlementReadyCheckReasonId
      },
      latestSettlementStep,
      settlementDateDetail,
      lodgementCaseIdToDocumentNameMap
    } = this.props;

    if (!Number.isInteger(workspaceStatusId)) {
      return null;
    }

    // calculate notification panel for primary lodgement case
    const primaryLodgementCase: LodgementCase | undefined = getDefaultLodgementCase(lodgementCases);
    const primaryLodgementCaseStatusId: LodgementCaseStatusEnum = resolveDefaultLodgementCaseStatus(lodgementCases);
    const notificationTypeForPLC: WorkspaceProgressPanelNotificationTypeEnum | undefined = resolveWorkspaceProgressNotificationPanelTypeForPLC({
      //
      workspaceStatusId: workspaceStatusId!,
      settlementStatusId,
      settlementStep: latestSettlementStep,
      settlementRollover: isRollover,
      lodgementCaseStatusId: primaryLodgementCaseStatusId,
      lastSettlementReadyCheckReasonId,
      settlementDateDetail: {
        isAcceptedByAll: settlementDateDetail.isAcceptedByAll,
        isAcceptedByUser: settlementDateDetail.isAcceptedByUser,
        proposedSettlementDate: settlementDateDetail.proposedSettlementDate
      }
    });

    const notificationsForSLC = resolveSecondaryLodgementCases(lodgementCases, participantId) //
      .reduce<
        Array<{
          notificationType: WorkspaceProgressPanelNotificationTypeEnum;
          documentName: string;
          lodgementCaseName: string;
        }>
      >((acc, { lodgementCaseStatusId, name, id }) => {
        const notificationType: WorkspaceProgressPanelNotificationTypeEnum | undefined = resolveWorkspaceProgressNotificationPanelTypeForSLC({ lodgementCaseStatusId });
        if (Number.isInteger(notificationType)) {
          acc.push({
            notificationType: notificationType!,
            documentName: lodgementCaseIdToDocumentNameMap[id],
            lodgementCaseName: name
          });
        }
        return acc;
      }, []);

    const showPLCNotification = notificationTypeForPLC !== undefined;
    const showSLCNotification = notificationsForSLC.length > 0;
    if (!(showPLCNotification || showSLCNotification)) {
      return null;
    }
    return (
      // workspace progress notification panel needs to stay under
      // global search(zIndex-4), top panel(zIndex-4), simple toast message(zIndex - 4)
      // other notification(zIndex - 3)
      <div className="z-[2] mb-8 flex flex-col gap-4">
        {showPLCNotification && (
          <>
            <WorkspaceProgressNotificationPanelBackground //
              notificationType={notificationTypeForPLC}
            />
            <WorkspaceProgressNotificationPanel //
              className="z-10"
              notificationType={notificationTypeForPLC}
              documentName={lodgementCaseIdToDocumentNameMap[primaryLodgementCase?.id ?? '']}
              lodgementCaseName={primaryLodgementCase?.name}
            />
          </>
        )}

        {
          // we want to always have the background behind the very top notification
          !showPLCNotification && showSLCNotification && (
            <WorkspaceProgressNotificationPanelBackground //
              notificationType={notificationsForSLC[0].notificationType}
            />
          )
        }
        {notificationsForSLC.map(({ notificationType, documentName, lodgementCaseName }, index) => (
          <WorkspaceProgressNotificationPanel //
            key={index}
            className="z-10"
            notificationType={notificationType}
            documentName={documentName}
            lodgementCaseName={lodgementCaseName}
          />
        ))}
      </div>
    );
  }

  private renderSelectedTab() {
    const {
      //
      isLoadingWorkspaceBasicInfo,
      isLoadingWorkspaceDetail,
      workspaceId,
      participantId,
      isAbandonedOrWithdrawnWorkspace,
      workspaceDetail,
      workspaceParticipants,
      workspaceTasksState,
      isMLCEnabled,
      dispatch,
      classes
    } = this.props;

    switch (this.state.selectedTab) {
      case TabEnum.Tasks:
        const { workspaceStatusId, isRollover, settlementStatusId } = workspaceDetail;
        const isTaskSectionAccessible = isTaskAccessible(workspaceStatusId);

        return (
          <div className="relative flex flex-col">
            {isMLCEnabled && this.renderWorkspaceProgressNotificationPanels()}
            <FlexLayout alignItems="flex-start">
              <FlexLayout className={classes.taskTabWrapper} flexDirection="column">
                <Box className={classNames(!isTaskSectionAccessible && 'opacity-40')}>
                  <div className={classNames('flex-grow', !isTaskSectionAccessible && 'pointer-events-none')}>
                    {isMLCEnabled ? (
                      <TabTasksV2 //
                        // route params
                        workspaceId={workspaceId}
                        participantId={participantId}
                        // redux
                        // basic info
                        isRollover={isRollover}
                        workspaceStatusId={workspaceStatusId}
                        settlementStatusId={settlementStatusId}
                        // tasks
                        workspaceTasksState={workspaceTasksState}
                        // other
                        isLoadingWorkspace={isLoadingWorkspaceBasicInfo || isLoadingWorkspaceDetail} // track only first load
                        backLink={resolveBackLink(workspaceDetail.workspaceTypeId).linkTo}
                        onTaskAction={dispatch}
                      />
                    ) : (
                      <TabTasksContainer //
                        workspaceId={workspaceId}
                        participantId={participantId}
                        backLink={resolveBackLink(workspaceDetail.workspaceTypeId).linkTo}
                      />
                    )}
                  </div>
                  <OffPlatformTasksContainer workspaceId={workspaceId} participantId={participantId} disabled={workspaceDetail.isLocked} />
                </Box>
                <WorkspaceFilesContainer //
                  workspaceId={workspaceId}
                  participantId={participantId}
                  participants={workspaceParticipants}
                  disabled={workspaceDetail.isLocked}
                  onUploadFile={this.handleUploadFileClick}
                  onEditFile={this.handleEditFileClick}
                  onDeleteFile={this.handleDeleteFileClick}
                />
              </FlexLayout>

              <FlexLayout flexDirection="column" className={classes.rightPanel}>
                {this.renderSettlementDetailCard()}
                {this.renderAcceptedParticipants()}
                {this.renderInvitations()}
                {this.renderAdditionalOptions()}
              </FlexLayout>
            </FlexLayout>
          </div>
        );

      case TabEnum.Logs:
        return isAbandonedOrWithdrawnWorkspace ? (
          this.renderLogTab()
        ) : (
          <FlexLayout alignItems="flex-start">
            <FlexLayout className={classes.taskTabWrapper} flexDirection="column">
              {this.renderLogTab()}
            </FlexLayout>

            <FlexLayout flexDirection="column" className={classes.rightPanel}>
              {this.renderSettlementDetailCard()}
              {this.renderAcceptedParticipants()}
              {this.renderInvitations()}
              {this.renderAdditionalOptions()}
            </FlexLayout>
          </FlexLayout>
        );

      case TabEnum.Reports:
        return <WorkspaceReport workspaceId={workspaceId} participantId={participantId} />;
      case TabEnum.LinkedWorkspaces:
        return (
          <TabLinkedWorkspaces //
            workspaceId={workspaceId}
            participantId={participantId}
          />
        );
      default:
        return 'notes TODO';
    }
  }

  private renderLogTab = () => {
    const {
      //
      workspaceId,
      participantId
    } = this.props;

    return (
      <TabLogsContainer //
        workspaceId={workspaceId}
        participantId={participantId}
      />
    );
  };

  private renderSettlementDetailCard() {
    const {
      //
      classes,
      workspaceId,
      participantId,
      workspaceDetail,
      settlementDateDetail,
      isLoadingSettlementDateDetails,
      isLoadingWorkspaceBasicInfo,
      isLoadingWorkspaceParticipants,
      onAcceptSettlementClick
    } = this.props;

    if (isLoadingWorkspaceBasicInfo || isLoadingSettlementDateDetails || isLoadingWorkspaceParticipants) {
      return (
        <Box className={classes.box}>
          <ListLoader lineCount={2} disableAvatar color="white" />
        </Box>
      );
    }

    const link = resolveSettlementDateLink({ workspaceId, participantId });
    const declineLink = resolveSettlementDateLink({ workspaceId: workspaceId, participantId: participantId, declined: true });

    return (
      <SettlementDateTimeCard
        // basic workspace info
        isLocked={workspaceDetail.isLocked}
        isInteroperable={workspaceDetail.isInteroperable}
        isRollover={workspaceDetail.isRollover}
        workspaceStatusId={workspaceDetail.workspaceStatusId}
        jurisdictionId={workspaceDetail.jurisdictionId}
        settlementDateTime={workspaceDetail.settlementDate}
        expectedSettlementDateTime={workspaceDetail.expectedSettlementDate}
        // settlement details info
        isAcceptedByAll={settlementDateDetail.settlementAwaitingAcceptanceCount === 0} // when there are no awaiting acceptance participants, it means settlementDate is accepted by all current participants
        isAcceptedByUser={settlementDateDetail.isAcceptedByUser}
        isProposedByUser={settlementDateDetail.isProposedByUser}
        localProposedSettlementDate={settlementDateDetail.localProposedSettlementDate}
        proposedSettlementDate={settlementDateDetail.proposedSettlementDate}
        settlementAwaitingAcceptanceCount={settlementDateDetail.settlementAwaitingAcceptanceCount}
        isUnsupported={settlementDateDetail.isUnsupported}
        // other
        editLink={link}
        declineLink={declineLink}
        onAcceptClick={onAcceptSettlementClick}
      />
    );
  }

  private renderAcceptedParticipants() {
    const {
      //
      workspaceId,
      participantId,
      isLoadingSettlementDateDetails,
      isLoadingWorkspaceParticipants,
      workspaceDetail,
      currentParticipant,
      workspaceParticipants,
      settlementDateDetail,
      classes
    } = this.props;

    const canInviteParticipants = Number.isInteger(currentParticipant.workspaceRoleId) && !workspaceDetail.isLocked;
    const acceptedParticipants = workspaceParticipants.filter(p => p.participantStatus.id === ParticipantStatusEnum.Accepted);

    return (
      <Box //
        title="Participants"
        className={classes.box}
        disabled={isLoadingWorkspaceParticipants || isLoadingSettlementDateDetails}
        action={canInviteParticipants ? 'Invite' : undefined}
        onActionClick={this.handleOnAddNewParticipantClick}
      >
        <ParticipantList
          dataTestId={`workspace-accepted-participants-${acceptedParticipants.length}`}
          loaderBackgroundColor="white"
          workspaceId={workspaceId}
          participantId={participantId}
          isLoading={isLoadingWorkspaceParticipants || isLoadingSettlementDateDetails}
          // list of participants that accepted invite
          items={acceptedParticipants}
          // list of participants that accepted settlement
          settlementAcceptedByParticipants={settlementDateDetail.acceptedByParticipants}
          //
        />
      </Box>
    );
  }

  private renderInvitations() {
    const {
      //
      workspaceId,
      participantId,
      isLoadingWorkspaceParticipants,
      isLoadingSettlementDateDetails,
      workspaceParticipants,
      settlementDateDetail,
      profile,
      classes
    } = this.props;

    const invitations = workspaceParticipants.filter(p => p.participantStatus.id === ParticipantStatusEnum.Pending);
    const isLoading = isLoadingSettlementDateDetails || isLoadingWorkspaceParticipants;

    if (!(isLoading || invitations.length)) {
      return null;
    }

    return (
      <Box //
        title="Awaiting invitation acceptance"
        typographyVariant="subtitle1"
        disabled={isLoading}
        className={classes.box}
      >
        <ParticipantList
          dataTestId={`workspace-awaiting-participants-${invitations.length}`}
          loaderBackgroundColor="white"
          workspaceId={workspaceId}
          participantId={participantId}
          items={invitations}
          isLoading={isLoading}
          settlementAcceptedByParticipants={settlementDateDetail.acceptedByParticipants}
          subscriberId={profile.subscriberId}
          isRenderWithdrawAndResendButtons={true}
        />
      </Box>
    );
  }

  private renderAdditionalOptions() {
    const {
      //
      workspaceId,
      participantId,

      workspaceDetail: {
        //
        isLocked,
        jurisdictionId,
        workspaceStatusId,
        workspaceTypeId,
        lodgementCases
      },
      currentParticipant: {
        //
        workspaceRoleId
      },
      workspaceParticipants,
      classes
    } = this.props;

    if (![jurisdictionId, workspaceRoleId, workspaceStatusId, workspaceTypeId].every(Number.isInteger)) {
      return null;
    }

    if (workspaceStatusId === WorkspaceStatusEnum.Archived || workspaceStatusId === WorkspaceStatusEnum.Abandoned || !workspaceParticipants.length) {
      return null;
    }

    // when workspace is locked, usually we don't show additional options, but there is exception,
    // FS can not be manually archived
    if (isLocked) {
      return null;
    }

    return (
      <Secured requiredUserPermissions={UserLevelPermissionEnum.ManageInvitations} hiddenForDisabled>
        <Box //
          title="Additional Options"
          className={classes.box}
        >
          <ManageWorkspaceBoxContainer //
            // route params
            workspaceId={workspaceId}
            participantId={participantId}
            // redux data
            workspaceStatusId={workspaceStatusId!}
            lodgementCases={lodgementCases || []}
            {...(workspaceTypeId === WorkspaceTypeEnum.RegistrationOnly
              ? {
                  workspaceTypeId: workspaceTypeId
                }
              : {
                  workspaceTypeId: workspaceTypeId as WorkspaceTypeEnum.FinancialSettlement, //TODO remove this once api-gateway is updated.
                  workspaceRoleId: workspaceRoleId!
                })}
          />
        </Box>
      </Secured>
    );
  }

  private renderInviteInWorkspaceParticipants() {
    const { openInviteParticipantDialog } = this.state;
    const { workspaceId, participantId, workspaceDetail, currentParticipant, isAbandonedOrWithdrawnWorkspace, workspaceParticipants } = this.props;

    if (isAbandonedOrWithdrawnWorkspace || !(Number.isInteger(currentParticipant.workspaceRoleId) && Number.isInteger(workspaceDetail.jurisdictionId))) {
      return null;
    }

    return (
      <InviteAdditionalParticipantsContainer
        // route params
        workspaceId={workspaceId}
        participantId={participantId}
        // workspace basic info
        isInteroperable={workspaceDetail.isInteroperable}
        jurisdictionId={workspaceDetail.jurisdictionId!}
        // current participant
        currentWorkspaceRoleId={currentParticipant.workspaceRoleId!}
        // other
        openDialog={openInviteParticipantDialog}
        onClose={this.handleOnDialogClose}
        workspaceType={workspaceDetail.workspaceTypeId!}
        currentParticipantGroupId={currentParticipant.groupId!}
        currentParticipantReference={currentParticipant.reference!}
        workspaceParticipants={workspaceParticipants}
      />
    );
  }

  private renderFileUpload() {
    const { openFileUploadDialog, selectedFile } = this.state;
    const { workspaceId, participantId, workspaceParticipants, workspaceDetail } = this.props;

    return (
      <WorkspaceFileUploadDialog
        workspaceId={workspaceId}
        participantId={participantId}
        participants={workspaceParticipants}
        editFile={selectedFile}
        openDialog={openFileUploadDialog}
        isWorkspaceInteroperable={workspaceDetail.isInteroperable}
        onClose={this.handleUploadFileDialogClose}
        workspaceType={workspaceDetail.workspaceTypeId}
      />
    );
  }

  private renderDeleteFile() {
    const { openDeleteFileDialog, selectedFile } = this.state;
    const { workspaceId, participantId } = this.props;

    return selectedFile ? (
      <WorkspaceFileDeleteDialog
        workspaceId={workspaceId}
        participantId={participantId}
        deleteFile={selectedFile}
        openDialog={openDeleteFileDialog}
        onClose={this.handleDeleteFileDialogClose}
      />
    ) : null;
  }

  private renderLinkedWorkspacesLabel() {
    const { linkedWorkspaces, classes } = this.props;

    return (
      <Badge classes={{ badge: classes.badge }} badgeContent={linkedWorkspaces.length} max={99} color="primary">
        Linked workspaces
      </Badge>
    );
  }

  private handleTabChange = (_unusedEvent: any, value: any) => {
    this.setState({ selectedTab: value });
  };

  private handleOnDialogClose = () => {
    this.setState({ openInviteParticipantDialog: false });
  };

  private handleOnAddNewParticipantClick = () => {
    const { workspaceId, participantId, dispatch } = this.props;
    dispatch(actionFetchAdditionalInvitableRoles.request({ workspaceId, participantId }));
    this.setState({ openInviteParticipantDialog: true });
  };

  private handleUploadFileClick = () => {
    this.setState({ selectedFile: null, openFileUploadDialog: true });
  };

  private handleUploadFileDialogClose = () => {
    this.setState({ openFileUploadDialog: false });
  };

  private handleEditFileClick = (file: WorkspaceFile) => {
    this.setState({ selectedFile: file, openFileUploadDialog: true });
  };

  private handleDeleteFileClick = (file: WorkspaceFile) => {
    this.setState({ selectedFile: file, openDeleteFileDialog: true });
  };

  private handleDeleteFileDialogClose = () => {
    this.setState({ openDeleteFileDialog: false });
  };
}

const styledComponent = withStyles(styles)(FinancialDetail);
export default styledComponent;
