import * as React from 'react';

import { FormikProps, FormikValues } from 'formik';
import { Action } from 'redux';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';

import { PaymentMethodEnum, WorkspaceRoleEnum } from '@sympli/api-gateway/enums';
import { WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import DirectionHeaderBox from 'src/containers/workspace/financial/directions/components/direction-header-box';
import { getVendorAmountDirectionDesc } from 'src/containers/workspace/financial/directions/helpers';
import { useWorkspaceParticipants } from 'src/containers/workspace/shared/detail/reducers/workspaceParticipants';
import useFeatureFlag from 'src/hooks/useFeatureFlag';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import { modelKey } from 'src/utils/formUtils';
import { DirectionTrustAccountLookupItemModel, DistributionFormikModel, TrustAccountMap } from '../../../../models';
import { PurchaserDirectionsFormModel } from '../../forms/purchaser-directions/models';
import { FinancialLineItemLabelEnum } from '../../models';
import AddNewRecord from '../add-new-record';
import DirectionPayeeDetail from '../direction-payee-detail';
import DirectionRecord from '../direction-record/DirectionRecord';
import FormGridHead from '../form-grid-head';
import { useStyles } from './styles';

export type FormModel = FormikValues & { distributions: Array<DistributionFormikModel> };

export interface Props<T extends FormModel> {
  canEdit: boolean;
  isMultipleEditorsOpen: boolean;
  formikProps: FormikProps<T>;
  currentParticipant: WorkspaceParticipantApiResponse;
  defaultValue: DistributionFormikModel;
  trustAccountOptions: Array<DirectionTrustAccountLookupItemModel>;
  trustAccountBankDetailMap: TrustAccountMap;
  paymentMethodOptions: Array<LookupEnumModel<PaymentMethodEnum>>;
  workspaceId: string;
  participantId: string;
  workspaceRole: WorkspaceRoleEnum;
  header?: string;
  children?: (index: number) => React.ReactNode;
  isFssLineItemSaveEnabled: boolean;
  dispatch: SafeDispatch<Action>;
}

// be aware, since all parties are having the same key name, so it is safe to use the below type
const distributionsFieldName = modelKey<PurchaserDirectionsFormModel>()('distributions');

function DirectionRecordList<T extends FormModel>({
  //
  trustAccountBankDetailMap,
  formikProps,
  header = 'Payments',
  currentParticipant,
  canEdit,
  defaultValue,
  trustAccountOptions,
  workspaceRole,
  workspaceId,
  participantId,
  paymentMethodOptions,
  children,
  isFssLineItemSaveEnabled,
  dispatch,
  isMultipleEditorsOpen
}: Props<T>) {
  const {
    values: { distributions }
  } = formikProps;
  const classes = useStyles();
  const workspaceParticipantsState = useWorkspaceParticipants(workspaceId);
  const description = getVendorAmountDirectionDesc(workspaceRole, distributions);
  const showBreakdownsCol: boolean = useFeatureFlag(FeatureToggleEnum.breakdownLineItems);

  return (
    <DirectionHeaderBox header={header} description={description} className={classes.headerBox}>
      <FormGridHead paymentDetailHeader="Payee and payment details" hasBreakdownsCol={showBreakdownsCol} />

      {distributions.map((item, index, distributions) => {
        const isEditable = !item.isLocked;
        const hasEditPermission = item.hasEditPermission;
        const openEditor = isEditable && item.isEditorOpen;
        const addDivider = canEdit || index !== distributions.length - 1;

        return (
          <React.Fragment key={`distributions-${index}`}>
            <DirectionRecord
              formikProps={formikProps}
              index={index}
              arrayFieldName={distributionsFieldName}
              isEditable={isEditable && canEdit}
              isMultipleEditorsOpen={isMultipleEditorsOpen}
              hasEditPermission={hasEditPermission}
              trustAccountMap={trustAccountBankDetailMap}
              workspaceRole={workspaceRole}
              allParticipants={workspaceParticipantsState.items}
              isFssLineItemSaveEnabled={isFssLineItemSaveEnabled}
              workspaceId={workspaceId}
              participantId={participantId}
              dispatch={dispatch}
              showBreakdownsCol={showBreakdownsCol}
            />
            <Collapse in={openEditor}>
              {children ? (
                children(index)
              ) : (
                <DirectionPayeeDetail
                  formikProps={formikProps}
                  index={index}
                  workspaceId={workspaceId}
                  participantId={participantId}
                  arrayFieldName={distributionsFieldName}
                  trustAccountOptions={trustAccountOptions}
                  trustAccountMap={trustAccountBankDetailMap}
                  paymentMethodOptions={paymentMethodOptions}
                />
              )}
            </Collapse>
            {addDivider && <Divider className={classes.divider} />}
          </React.Fragment>
        );
      })}

      {canEdit && (
        <AddNewRecord
          formikProps={formikProps}
          fieldName={distributionsFieldName}
          defaultValue={defaultValue}
          currentParticipant={currentParticipant}
          subscriberName={currentParticipant.name}
          recordName={FinancialLineItemLabelEnum.Payment}
        />
      )}
    </DirectionHeaderBox>
  );
}

export default DirectionRecordList;
