import queryString from 'query-string';
import { call, put, takeLatest } from 'typed-redux-saga';

import { convertRangeDateToIsoDate } from 'src/components/date-range-picker/helpers';
import { actionOpenGlobalSimpleNotification } from 'src/components/global-simple-notification/actions';
import { AssignmentTypeEnum } from 'src/containers/dashboard/shared/models';
import environments from 'src/environments';
import http, { NoResponseData } from 'src/utils/http';
import {
  actionFetchInvitationsArchivedFeed,
  actionFetchInvitationsFeedV2,
  actionFetchInvitationsSubscribers,
  actionResendInvite,
  actionWithdrawParticipantInvitation,
  ResendInviteApiRequest
} from './actions';
import { getInvitationDashboardArchivedDetails } from './api';
import {
  InvitationsDashboardV2ApiRequest,
  InvitationsDashboardV2ApiResponse,
  InvitationsSubscriberSearchApiRequest,
  InvitationsSubscriberSearchApiResponse,
  WithdrawParticipantInvitationRequestModel
} from './models';

// note: dashboard is using v2 endpoint
const { TARGET_MACHINE, TARGET_API_V2 } = environments;

const fetchInvitationsDashboardV2Feed = (q: InvitationsDashboardV2ApiRequest) => {
  const uri = '/dashboards/invitations';
  const { assignmentType: existingAssignmentType, settlementPeriod, lastInvitationSentPeriod, archivedDatePeriod, ...rest } = q;

  // resolve all the iso date
  let dateObject: Partial<{
    //
    'settlementPeriod.start': string | null;
    'settlementPeriod.end': string | null;
    'lastInvitationSentPeriod.start': string | null;
    'lastInvitationSentPeriod.end': string | null;
    'archivedDatePeriod.start': string | null;
    'archivedDatePeriod.end': string | null;
  }> = {};

  if (settlementPeriod) {
    const { start, end } = convertRangeDateToIsoDate(settlementPeriod);
    dateObject = {
      'settlementPeriod.start': start,
      'settlementPeriod.end': end
    };
  } else if (settlementPeriod === null) {
    dateObject = {
      'settlementPeriod.start': undefined,
      'settlementPeriod.end': undefined
    };
  }

  if (lastInvitationSentPeriod) {
    const { start, end } = convertRangeDateToIsoDate(lastInvitationSentPeriod);
    dateObject = {
      'lastInvitationSentPeriod.start': start,
      'lastInvitationSentPeriod.end': end
    };
  } else if (lastInvitationSentPeriod === null) {
    dateObject = {
      ...dateObject,
      'lastInvitationSentPeriod.start': undefined,
      'lastInvitationSentPeriod.end': undefined
    };
  }

  if (archivedDatePeriod) {
    const { start, end } = convertRangeDateToIsoDate(archivedDatePeriod);
    dateObject = {
      'archivedDatePeriod.start': start,
      'archivedDatePeriod.end': end
    };
  } else if (archivedDatePeriod === null) {
    dateObject = {
      ...dateObject,
      'archivedDatePeriod.start': undefined,
      'archivedDatePeriod.end': undefined
    };
  }

  // reassign is equal to assign to group
  const assignmentType = existingAssignmentType === AssignmentTypeEnum.ReAssigned ? AssignmentTypeEnum.AssignedToGroup : existingAssignmentType;

  const query = queryString.stringify({
    ...rest,
    ...dateObject,
    assignmentType
  });

  return http.get<InvitationsDashboardV2ApiResponse>(uri + '?' + query, { baseURL: `${TARGET_MACHINE}${TARGET_API_V2}` });
};

function* sagaFetchInvitationsFeedV2(action: ReturnType<typeof actionFetchInvitationsFeedV2.request>) {
  try {
    const data = yield* call(fetchInvitationsDashboardV2Feed, action.payload);

    yield put(actionFetchInvitationsFeedV2.success({ data }));
  } catch (error) {
    yield put(actionFetchInvitationsFeedV2.failure({ error }));
  }
}

const fetchInvitationsSubscribers = (q: InvitationsSubscriberSearchApiRequest) => {
  const uri = '/dashboards/invitations/subscribers';
  const query = queryString.stringify(q);

  return http.get<InvitationsSubscriberSearchApiResponse>(uri + '?' + query, { baseURL: `${TARGET_MACHINE}${TARGET_API_V2}` });
};

function* sagaFetchInvitationsSubscribers(action: ReturnType<typeof actionFetchInvitationsSubscribers.request>) {
  try {
    const data = yield* call(fetchInvitationsSubscribers, action.payload);

    yield put(actionFetchInvitationsSubscribers.success({ data }));
  } catch (error) {
    //
    yield put(actionFetchInvitationsSubscribers.failure({ error }));
  }
}

// new v2 endpoint
const withdrawParticipantInvitationV2 = (payload: WithdrawParticipantInvitationRequestModel) => {
  const { workspaceId, participantId, isLinkedWorkspace } = payload;

  const uri = `/invitations/withdraw`;
  return http.post<undefined>(uri, { workspaceId, participantId, isLinkedWorkspace }, { baseURL: `${TARGET_MACHINE}${TARGET_API_V2}` });
};

function* sagaWithdrawParticipantInvitation(action: ReturnType<typeof actionWithdrawParticipantInvitation.request>) {
  try {
    yield* call(withdrawParticipantInvitationV2, action.payload);

    yield put(actionWithdrawParticipantInvitation.success());

    yield put(
      actionOpenGlobalSimpleNotification({
        //
        message: (
          <>
            <b>Invitation Withdrawn.</b> The invite has been successfully withdrawn
          </>
        ),
        autoHideDuration: 5000,
        variant: 'new-success'
      })
    );

    if (action.payload.query) {
      // !important refresh the whole page
      if (action.payload.isNewDashboard) {
        yield put(actionFetchInvitationsFeedV2.request(action.payload.query as InvitationsDashboardV2ApiRequest));
      }
    }
  } catch (error) {
    if (error.response?.status === 409) {
      error.title = 'Invitation error';
    }
    yield put(actionWithdrawParticipantInvitation.failure({ error }));
  }
}

const resendInvite = (payload: ResendInviteApiRequest) => {
  return http.post<NoResponseData>(`/dashboards/resend-invite/${encodeURIComponent(payload.id)}`, {});
};

function* sagaResendInvite(action: ReturnType<typeof actionResendInvite.request>) {
  try {
    yield* call(resendInvite, action.payload);

    yield put(actionResendInvite.success());

    if (action.payload.query) {
      // !important refresh the whole page
      if (action.payload.isNewDashboard) {
        yield put(actionFetchInvitationsFeedV2.request(action.payload.query as InvitationsDashboardV2ApiRequest));
      }
    }

    yield put(
      actionOpenGlobalSimpleNotification({
        //
        message: (
          <>
            <b>Invitation Resent.</b> The invite has been successfully resent
          </>
        ),
        autoHideDuration: 5000,
        variant: 'new-success'
      })
    );
  } catch (error) {
    if (error.response?.status === 409) {
      yield put(
        actionOpenGlobalSimpleNotification({
          //
          message: 'An invite has already been sent to this subscriber in the last 24 hours',
          autoHideDuration: 2000,
          variant: 'error'
        })
      );
    } else {
      yield put(actionResendInvite.failure({ error }));
    }
  }
}

function* sagaFetchInvitationsArchivedFeed(action: ReturnType<typeof actionFetchInvitationsArchivedFeed.request>) {
  try {
    const data = yield* call(getInvitationDashboardArchivedDetails, action.payload);

    yield put(actionFetchInvitationsArchivedFeed.success({ data }));
  } catch (error) {
    //
    yield put(actionFetchInvitationsArchivedFeed.failure({ error }));
  }
}

export default [
  //
  takeLatest(actionFetchInvitationsFeedV2.request, sagaFetchInvitationsFeedV2),
  takeLatest(actionFetchInvitationsSubscribers.request, sagaFetchInvitationsSubscribers),
  takeLatest(actionWithdrawParticipantInvitation.request, sagaWithdrawParticipantInvitation),
  takeLatest(actionResendInvite.request, sagaResendInvite),
  takeLatest(actionFetchInvitationsArchivedFeed.request, sagaFetchInvitationsArchivedFeed)
];
