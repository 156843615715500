import * as React from 'react';

import classNames from 'classnames';
import { addMonths, differenceInCalendarMonths } from 'date-fns';
import dateFormat from 'dateformat';
import { Form, FormikProps } from 'formik';
import _uniqueId from 'lodash-es/uniqueId';
import { NavigateFunction } from 'react-router-dom';
import { Action } from 'redux';
import Divider from '@mui/material/Divider';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { JurisdictionsEnum, SettlementDateKindEnum } from '@sympli/api-gateway/enums';
import { ExpectedSettlementDate } from '@sympli/api-gateway/shared';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { PortalSource } from '@sympli/ui-framework/components/portal';
import SympliButton from '@sympli/ui-framework/components/sympli-button';
import WizardStepper from '@sympli/ui-framework/components/wizard-stepper';
import { IconCaretLeft, IconCaretRight } from '@sympli/ui-framework/icons';
import { LookupItemModel } from '@sympli/ui-framework/models';

import { settlementTimeOptionsSelector } from 'src/containers/dashboard/components/create-new-workspace/views/financial-form/steps/matter-detail/helpers';
import { DateValueMapModel, RescindApprovalModel } from 'src/containers/workspace/financial/settlement-date/models';
import { resolveWorkspaceDetailLink } from 'src/containers/workspace/shared/detail/helpers';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import { jurisdictionNameMapping } from 'src/models/jurisdictions';
import { modelKey } from 'src/utils/formUtils';
import { getTimeInTimeZone } from 'src/utils/timezone/timezoneHelper';
import { actionFetchCalendarItems } from '../../../actions';
import { getSubmitTime, TimeFormatTypeEnum, timeStringConvert } from '../../../helper';
import { DateFormatEnum, SettlementDateFormModel } from '../../../models';
import MonthTable from '../../month-table';
import SettlementTimeAndReasonEdit, { SettlementTimeTypeEnum } from '../settlement-time-and-reason-edit/SettlementTimeAndReasonEdit';
import styles, { ClassKeys } from './styles';

export const PORTAL_ID_FOR_SETTLEMENT_DATE_EDIT_TOP_PANEL = _uniqueId('settlement-date-edit-top-panel');
const CURRENT_DATE = new Date();

interface OwnProps {
  formikProps: FormikProps<SettlementDateFormModel>;
  workspaceSettlementDate?: string; // * '2018-04-04T13:00:00'
  expectedSettlementDate?: ExpectedSettlementDate;
  workspaceId: string;
  participantId?: string;
  jurisdictionId: JurisdictionsEnum;
  reasonText?: string;
  declined?: boolean;
  settlementAcceptedByAll: boolean;
  onSettlementDateChange(newWorkspaceSettlementDate: string): void;
  calendarYearAndMonth: string;
  calendarItems: DateValueMapModel;
  error?: string;
  dispatch: SafeDispatch<Action>;
  navigate: NavigateFunction;
  rescindApprovalData: RescindApprovalModel;
  isRevokeSettlementDateApprovalEnabled: boolean;
  proposedSettlementDate?: string;
  isDateUnsupported?: boolean;
}

type Props = OwnProps & WithStyles<ClassKeys>;

const fieldName = modelKey<SettlementDateFormModel>();

class RenderedEditForm extends React.PureComponent<Props> {
  // * we store here formatted value for comparison with selected date and time
  private workspaceSettlementDateFormatted: string; // "yyyy-mm-dd"
  private workspaceSettlementTimeFormatted: string; // "HH:MM"
  private dateSelected = false;
  private proposeButtonLabel = 'Propose new date and time';

  constructor(props: Props) {
    super(props);

    this.workspaceSettlementDateFormatted = dateFormat(props.workspaceSettlementDate, DateFormatEnum.DATE); // "yyyy-mm-dd"
    this.workspaceSettlementTimeFormatted = dateFormat(props.workspaceSettlementDate, DateFormatEnum.TIME); // "HH:MM"
  }

  private isNewTime(selectedDate: string, selectedTime: string) {
    // "yyyy-mm-dd" - "HH:MM"
    return selectedDate !== this.workspaceSettlementDateFormatted || selectedTime !== this.workspaceSettlementTimeFormatted;
  }

  get isButtonDisabled() {
    const {
      formikProps: {
        values: { selectedDate, selectedTime, reasonId },
        isSubmitting
      },
      declined,
      expectedSettlementDate,
      proposedSettlementDate
    } = this.props;
    const isValidTime = this.isValidTime(selectedDate, selectedTime);
    // We need to keep the submit buttons disabled unless either a new date/time/reason is chosen or the form is submitting
    const disabled =
      (declined && !this.dateSelected) ||
      (!reasonId &&
        (expectedSettlementDate?.settlementDateKind === SettlementDateKindEnum.DateAndTime || (proposedSettlementDate !== undefined && proposedSettlementDate !== null))) ||
      isSubmitting ||
      !isValidTime;

    return disabled;
  }

  private isValidTime(selectedDate: string, settleTime: string) {
    const { jurisdictionId } = this.props;
    if (!settleTime) {
      return false;
    }
    const selectTimeMs = Date.parse(`${selectedDate} ${settleTime}`);

    const currentTimeInJurisdiction = getTimeInTimeZone(new Date(), jurisdictionId);
    const currentTimeInJurisdictionMs = currentTimeInJurisdiction.getTime();
    return selectTimeMs >= currentTimeInJurisdictionMs;
  }

  render() {
    const {
      calendarYearAndMonth,
      workspaceSettlementDate,
      classes,
      formikProps,
      calendarItems,
      jurisdictionId,
      reasonText,
      settlementAcceptedByAll,
      rescindApprovalData,
      isRevokeSettlementDateApprovalEnabled,
      proposedSettlementDate,
      expectedSettlementDate,
      dispatch,
      isDateUnsupported
    } = this.props;

    let {
      values: { selectedDate, selectedTime },
      errors: { selectedDate: dateError },
      isSubmitting
    } = formikProps;

    const settlementTimeOptions = this.getTimeOptionsWithSettlements(selectedDate);
    const settlementTimeType =
      (!expectedSettlementDate || expectedSettlementDate?.settlementDateKind === SettlementDateKindEnum.DateOnly) && !proposedSettlementDate
        ? SettlementTimeTypeEnum.Initial
        : this.isNewTime(selectedDate, selectedTime) || (workspaceSettlementDate === proposedSettlementDate && !settlementAcceptedByAll)
          ? SettlementTimeTypeEnum.Proposed
          : SettlementTimeTypeEnum.Current;
    const onCellClick = isSubmitting ? undefined : this.handleCellClick;
    const topSubmitButton: JSX.Element = (
      <FlexLayout className={classes.topPanelV2}>
        <SympliButton type="button" color="primary" variant="contained" arrowRight isLoading={isSubmitting} disabled={this.isButtonDisabled} onClick={formikProps.submitForm}>
          {this.proposeButtonLabel}
        </SympliButton>
      </FlexLayout>
    );

    const dateTimeDisplay =
      !expectedSettlementDate && !proposedSettlementDate
        ? ''
        : !selectedTime
          ? dateFormat(selectedDate, 'ddd d mmm yyyy')
          : `${dateFormat(selectedDate, 'ddd d mmm yyyy')}, at ${timeStringConvert(selectedTime)}`;
    return (
      <Form>
        {<PortalSource id={PORTAL_ID_FOR_SETTLEMENT_DATE_EDIT_TOP_PANEL}>{topSubmitButton}</PortalSource>}
        <FlexLayout flexDirection="row" flexWrap="wrap" className={classes.marginBottom}>
          <FlexLayout flexDirection="column" className={classNames(classes.calendarContainer)}>
            {this.renderPageHead()}
            <MonthTable
              className={classes.flexGrow}
              yearAndMonth={calendarYearAndMonth}
              data={calendarItems}
              selectedDate={selectedDate}
              currentSettlementDate={workspaceSettlementDate}
              onCellClick={onCellClick}
              jurisdictionId={jurisdictionId}
              settlementAcceptedByAll={settlementAcceptedByAll}
              isEditing={true}
              proposedSettlementDate={proposedSettlementDate}
              isDateUnsupported={isDateUnsupported}
            />
            {dateError && (
              <FormHelperText role="alert" error classes={{ error: classes.helperTextError }} data-error-name={fieldName('selectedDate')}>
                {dateError}
              </FormHelperText>
            )}
          </FlexLayout>

          <SettlementTimeAndReasonEdit
            dateTimeDisplay={dateTimeDisplay}
            settlementTimeType={settlementTimeType}
            isEditable={!isSubmitting && !!selectedDate}
            jurisdictionId={jurisdictionId}
            settlementTimeOptions={settlementTimeOptions}
            handleOnTimeChange={this.handleOnTimeChange}
            reasonText={reasonText}
            rescindApprovalData={rescindApprovalData}
            isRevokeSettlementDateApprovalEnabled={isRevokeSettlementDateApprovalEnabled}
            dispatch={dispatch}
            isCurrentSettlementDateUnsupported={isDateUnsupported}
          />
        </FlexLayout>
        <Divider />
        {this.renderStepper()}
      </Form>
    );
  }

  private renderPageHead() {
    const {
      classes,
      calendarYearAndMonth,
      formikProps: { isSubmitting }
    } = this.props;
    const goToTodayDate = isSubmitting ? undefined : this.goToTodayDate;
    return (
      <FlexLayout className={classes.paginationPanel} alignItems="center" justifyContent="space-between">
        <div>{this.renderPagination(isSubmitting)}</div>
        <div className={classes.yearMonthDisplay}>
          <strong>{dateFormat(calendarYearAndMonth, 'mmmm yyyy')}</strong>
        </div>
        <SympliButton variant="text" className={classes.today} onClick={goToTodayDate} color="inherit">
          Today
        </SympliButton>
      </FlexLayout>
    );
  }

  private renderStepper() {
    return (
      <WizardStepper
        isLoading={this.props.formikProps.isSubmitting}
        disabled={this.isButtonDisabled}
        nextLabel={this.proposeButtonLabel}
        backLabel="Cancel"
        onBack={this.onCancel}
      />
    );
  }

  private onCancel = () => {
    const { workspaceId, participantId } = this.props;

    const link = resolveWorkspaceDetailLink({ workspaceId, participantId });
    this.props.navigate(link);
  };

  private renderPagination(isSubmitting: boolean) {
    const { classes, calendarYearAndMonth } = this.props;
    const pastMonthDisabled = isSubmitting || differenceInCalendarMonths(new Date(calendarYearAndMonth), CURRENT_DATE) <= 0;
    // we don't allow to select a settlement date over 12 month
    const futureMonthDisabled = isSubmitting || differenceInCalendarMonths(new Date(calendarYearAndMonth), CURRENT_DATE) >= 12;

    return (
      <div>
        <IconButton
          color="primary"
          onClick={this.goToPreviousMonth}
          disabled={pastMonthDisabled}
          className={classNames(classes.paginationButton, pastMonthDisabled && classes.disabled)}
          size="large"
        >
          <IconCaretLeft width="18" height="18" color="primary" />
        </IconButton>
        <IconButton
          color="primary"
          onClick={this.goToNextMonth}
          disabled={futureMonthDisabled}
          className={classNames(classes.paginationButton, futureMonthDisabled && classes.disabled)}
          size="large"
        >
          <IconCaretRight width="18" height="18" color="primary" />
        </IconButton>
      </div>
    );
  }

  private handleCellClick = (event, item, date) => {
    const { formikProps } = this.props;
    const { values, setFieldValue } = formikProps;
    const selectedDate = dateFormat(date, DateFormatEnum.DATE);
    setFieldValue(fieldName('selectedDate'), selectedDate);

    this.announceNewSettlementProposal(selectedDate, values.selectedTime);
  };

  private handleOnTimeChange = (...args) => {
    // * Select onChange handler: onChange?: (event: React.ChangeEvent<HTMLInputElement>, resolvedValue: any) => void;
    const selectedTime = args[1];
    const { values } = this.props.formikProps;

    this.announceNewSettlementProposal(values.selectedDate, selectedTime);
  };

  private announceNewSettlementProposal(selectedDate: string, selectedTime: string) {
    this.dateSelected = true;
    const newWorkspaceSettlementDate = getSubmitTime(selectedDate, selectedTime);

    this.props.onSettlementDateChange(newWorkspaceSettlementDate);
  }

  private fetchCalendar(monthYear: string) {
    const { jurisdictionId, dispatch } = this.props;
    const jurisdiction = jurisdictionNameMapping[jurisdictionId];

    dispatch(actionFetchCalendarItems.request({ jurisdiction, calendarYearAndMonth: monthYear }));
  }

  private goToPreviousMonth = () => {
    const { calendarYearAndMonth } = this.props;
    const previousMonth = dateFormat(addMonths(new Date(calendarYearAndMonth), -1), DateFormatEnum.DATE);
    this.fetchCalendar(previousMonth);
  };

  private goToNextMonth = () => {
    const { calendarYearAndMonth } = this.props;
    const nextMonth = dateFormat(addMonths(new Date(calendarYearAndMonth), 1), DateFormatEnum.DATE);
    this.fetchCalendar(nextMonth);
  };

  private goToTodayDate = () => {
    const { formikProps } = this.props;
    const { setFieldValue, values } = formikProps;
    const todayDate = dateFormat(new Date(), DateFormatEnum.DATE);
    this.fetchCalendar(todayDate);
    setFieldValue(fieldName('selectedDate'), todayDate);
    this.announceNewSettlementProposal(todayDate, values.selectedTime);
  };

  private getTimeOptionsWithSettlements = (selectedDate: string) => {
    const hourCount = this.props.calendarItems[selectedDate]?.hourCount || {};
    const settlementTimeOptions = settlementTimeOptionsSelector({
      //
      jurisdictionId: this.props.jurisdictionId,
      format: TimeFormatTypeEnum._12H,
      settleDate: selectedDate
    }).map((item: LookupItemModel) => {
      const settlementNumber = hourCount[item.id];
      return settlementNumber ? { ...item, name: `${item.name} (${settlementNumber} settlements)` } : item;
    });
    return settlementTimeOptions;
  };
}

const styledComponent = withStyles(styles)(RenderedEditForm);

export default styledComponent;
