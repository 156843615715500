import { createSelector } from 'reselect';

import { ClientWorkspaceRoleEnum, JurisdictionsEnum, SubscriberOrganisationTypeEnum } from '@sympli/api-gateway/enums';
import { SubscriberWorkspaceRoleModel, UserProfileModel } from '@sympli/api-gateway/shared';
import { LookupEnumModel } from '@sympli/ui-framework/models';

import { getWorkspaceRoleTypeOptions } from 'src/models/roles';
import { resolveWorkspaceRoleTypeOptionsInTransferEAP } from './helpers';

interface WorkspaceRoleTypeOptionsSelectorParams {
  subscriberProfile: UserProfileModel;
  jurisdiction: JurisdictionsEnum | null;
  isReapEnabled: boolean;
  isTransferEAPEnabled: boolean;
  areCriticalRolesEnabled: boolean;
  areCriticalRolesNSWEnabled: boolean;
}

const getSubscriberProfileWorkspaceRoles = (data: WorkspaceRoleTypeOptionsSelectorParams) => data.subscriberProfile.subscriberWorkspaceRoles;
const getSubscriberProfileOrganisationType = (data: WorkspaceRoleTypeOptionsSelectorParams) => data.subscriberProfile.subscriberOrganisationType;

export const workspaceRoleTypeOptionsSelector = createSelector<
  WorkspaceRoleTypeOptionsSelectorParams,
  SubscriberWorkspaceRoleModel[],
  SubscriberOrganisationTypeEnum,
  JurisdictionsEnum | null,
  boolean,
  boolean,
  boolean,
  boolean,
  LookupEnumModel<ClientWorkspaceRoleEnum>[]
>(
  getSubscriberProfileWorkspaceRoles,
  getSubscriberProfileOrganisationType,
  data => data.jurisdiction,
  data => data.isReapEnabled,
  data => data.isTransferEAPEnabled,
  data => data.areCriticalRolesEnabled,
  data => data.areCriticalRolesNSWEnabled,
  (roles, organisationType, jurisdiction, isReapEnabled, isTransferEAPEnabled, areCriticalRolesEnabled, areCriticalRolesNSWEnabled) => {
    const enabledRoles: ClientWorkspaceRoleEnum[] = roles.filter(role => role.isEnabled).map(role => role.workspaceRole);
    const enabledRolesSet = new Set<ClientWorkspaceRoleEnum>(enabledRoles);

    const roleTypeOptions: LookupEnumModel<ClientWorkspaceRoleEnum>[] = getWorkspaceRoleTypeOptions(
      isReapEnabled,
      jurisdiction,
      areCriticalRolesEnabled,
      areCriticalRolesNSWEnabled
    );
    const result: LookupEnumModel<ClientWorkspaceRoleEnum>[] = roleTypeOptions.filter(role => enabledRolesSet.has(role.id));
    const roleTypeOptionsInTransferEAP = resolveWorkspaceRoleTypeOptionsInTransferEAP(isTransferEAPEnabled, organisationType, result);

    return roleTypeOptionsInTransferEAP;
  }
);
