import * as React from 'react';

import { useNavigate } from 'react-router-dom';

import { DocumentStatusEnum, JurisdictionsEnum, LodgementCaseStatusEnum, WorkspaceStatusEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { LodgementCase } from '@sympli/api-gateway/shared';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';

import { useProfile } from 'src/containers/shared/auth/reducers';
import { useWorkspaceBasicInfo } from 'src/containers/workspace/shared/detail/reducers/workspaceBasicInfo';
import { useWorkspaceDetail } from 'src/containers/workspace/shared/detail/reducers/workspaceDetail';
import { useWorkspaceDocuments } from 'src/containers/workspace/shared/detail/reducers/workspaceDocuments';
import { usePreviousValue, useRouterParams, useStoreSelector } from 'src/hooks';
import { useLrsInfo } from 'src/reducers/lrsInfo';
import { findCurrentWorkspaceParticipantSelector } from '../shared/workspace-detail-box/selectors';
import { useWorkspaceParticipants } from '../workspace/shared/detail/reducers/workspaceParticipants';
import VerifyingNotification from './components/verifying-notification';
import DocumentsPage from './DocumentsPage';
import { resolveDocumentDetailLink } from './helpers';
import { DocumentPageRouteParams } from './models';
import { useDocumentDetail } from './reducers/document';
import { documentLodgementDetailSelector } from './selectors';
import { useStyles } from './styles';

function DocumentsPageContainer() {
  const navigate = useNavigate();
  const queryParams = useRouterParams<DocumentPageRouteParams>();
  const { workspaceId, participantId, documentId } = queryParams;
  const profileState = useProfile();

  const workspaceBasicInfoState = useWorkspaceBasicInfo(workspaceId);
  const workspaceDetailState = useWorkspaceDetail(workspaceId, participantId);
  const workspaceDocumentsState = useWorkspaceDocuments(workspaceId, participantId);
  const documentDetailState = useDocumentDetail(workspaceId, participantId, documentId);
  const documentMergedPdfState = useStoreSelector(store => store.documentMergedPdf);
  const workspaceParticipantsState = useWorkspaceParticipants(workspaceId);

  const lodgementDetail = documentLodgementDetailSelector({
    workspaceDocuments: workspaceDocumentsState,
    document: documentDetailState
  });
  const classes = useStyles();

  const profileUserId: string = profileState.data!.userId;

  const lodgementCaseId = documentDetailState.detail?.lodgementCaseId;
  const jurisdictionId: JurisdictionsEnum | undefined = workspaceBasicInfoState.detail?.jurisdictionId;
  const lodgementCase: LodgementCase | undefined = workspaceBasicInfoState.detail?.lodgementCases.find(x => x.id === lodgementCaseId);
  const currentWorkspaceParticipant = findCurrentWorkspaceParticipantSelector({
    participantId,
    workspaceParticipants: workspaceParticipantsState,
    lodgementCase
  });
  const lodgementCaseStatusId: LodgementCaseStatusEnum | undefined = lodgementCase?.lodgementCaseStatusId;
  const workspaceStatusId: WorkspaceStatusEnum | undefined = workspaceBasicInfoState.detail?.workspaceStatusId;
  const workspaceTypeId: WorkspaceTypeEnum | undefined = workspaceBasicInfoState.detail?.workspaceTypeId;
  const isLodgementOnly = Number.isInteger(workspaceTypeId) && workspaceTypeId === WorkspaceTypeEnum.RegistrationOnly;
  const lrsInfo = useLrsInfo(jurisdictionId);

  const verifying = documentDetailState.detail?.status === DocumentStatusEnum.LodgementVerificationInProgress;
  // it's important to handle isRefetching as well in order to make sure we get refreshed http cookie before we try to load PDF
  const isLoading = workspaceBasicInfoState.isLoading || documentDetailState.isLoading || documentDetailState.isRefetching;

  const documentList = workspaceDocumentsState.items;

  const previousLodgementCaseStatusId = usePreviousValue(lodgementCaseStatusId);
  const [lvFailed, setLvFailed] = React.useState(false);

  React.useEffect(() => {
    // when lodgementCaseStatusId transitions from LodgementVerificationRequested to LodgementVerificationError, set lvFailed to true
    if (previousLodgementCaseStatusId === LodgementCaseStatusEnum.LodgementVerificationRequested && lodgementCaseStatusId === LodgementCaseStatusEnum.LodgementVerificationError) {
      setLvFailed(true);
    }
  }, [lodgementCaseStatusId, previousLodgementCaseStatusId]);

  React.useEffect(() => {
    // reset lvFailed when a different document is opened (we don't want to redirect
    // to the failed document every time user makes change in a different document)
    setLvFailed(false);
  }, [documentId]);

  React.useEffect(() => {
    // if LV failed, find the failed document and redirect to it if it is not currently open
    if (!lvFailed) {
      return;
    }
    const lvFailedDocument = documentList.find(d => d.documentStatus.id === DocumentStatusEnum.ResolveIssues);
    if (lvFailedDocument && lvFailedDocument.documentId !== documentId) {
      const documentLink = resolveDocumentDetailLink({ participantId, workspaceId, documentId: lvFailedDocument.documentId });
      navigate(documentLink);
    }
  }, [documentId, documentList, lvFailed, navigate, participantId, workspaceId]);

  return (
    <>
      {verifying ? <VerifyingNotification /> : null}
      <FlexLayout flexDirection="column" fullWidth fullHeight className={classes.root}>
        {/* We want to make sure both workspace detail and document detail are present before we show the document content */}
        {isLoading ? (
          <BlockLoader />
        ) : (
          Number.isInteger(jurisdictionId) && //
          Number.isInteger(lodgementCaseStatusId) && //
          Number.isInteger(workspaceStatusId) &&
          documentDetailState.detail && (
            <DocumentsPage //
              profileUserId={profileUserId}
              isFinancial={!isLodgementOnly}
              queryParams={queryParams}
              workspaceDetail={{
                // workspace basic info
                jurisdictionId: jurisdictionId!,
                // workspace detail
                workspaceLodgementDate: workspaceDetailState.detail?.lodgementDetails.find(x => x.lodgementCaseId === lodgementCaseId)?.dateTime,
                // LRS info
                isValidOperatingHoursForRegOnly: lrsInfo.isValidOperatingHoursForRegOnly,
                lodgementCases: workspaceDetailState.detail?.lodgementCases,
                isLocked: workspaceDetailState.detail?.isLocked
              }}
              detail={documentDetailState.detail}
              documentList={documentList}
              documentMergedPdfState={documentMergedPdfState}
              verifying={verifying}
              currentParticipant={currentWorkspaceParticipant}
              participants={workspaceParticipantsState.items}
              lodgementDetail={lodgementDetail}
              lodgementCase={lodgementCase!}
            />
          )
        )}
      </FlexLayout>
    </>
  );
}

export default React.memo(DocumentsPageContainer);
