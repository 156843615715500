import * as React from 'react';
import { useCallback, useMemo } from 'react';

import { useDispatch } from 'react-redux';
import FormHelperText from '@mui/material/FormHelperText';

import { WorkspaceTypeEnum } from '@sympli/api-gateway/enums';

import { Box } from 'src/components/layout';
import { ListLoader } from 'src/components/loaders';
import { actionSetDocumentListAction } from 'src/containers/workspace/shared/detail/actions';
import { useWorkspaceBasicInfo } from 'src/containers/workspace/shared/detail/reducers/workspaceBasicInfo';
import { useWorkspaceDetail } from 'src/containers/workspace/shared/detail/reducers/workspaceDetail';
import { useWorkspaceDocuments } from 'src/containers/workspace/shared/detail/reducers/workspaceDocuments';
import { useRouterParams, useSafeDispatch } from 'src/hooks';
import WorkspaceExpansionBox from '../workspace-expansion-box';
import DocumentListTitle from './components/document-list-title';
import DocumentList from './DocumentList';
import DocumentListMLC from './DocumentListMLC';
import { groupDocumentsByLodgementCaseId, isToolbarAvailableForWorkspace, resolveDocumentListItem101, sortingByLodgementCase } from './helpers';
import { DocumentListAction, LodgementCaseDetail, RouteParams } from './models';

// DOCS: https://tickleme.atlassian.net/wiki/spaces/DEV/pages/2083291137/document+list+-+data+dependency+insights
interface Props {
  // route props
  workspaceId: string;
  participantId: string;
  // other
  collapsedByDefault?: boolean;
  onExpandChange?(expand: boolean): void;
}

function DocumentListContainer({
  // route props
  workspaceId,
  participantId,
  // other
  collapsedByDefault,
  onExpandChange
}: Props) {
  const { documentId } = useRouterParams<RouteParams>();
  const dispatch = useSafeDispatch(useDispatch());
  const workspaceBasicInfoState = useWorkspaceBasicInfo(workspaceId);
  const workspaceDetailState = useWorkspaceDetail(workspaceId, participantId);
  const workspaceDocumentsState = useWorkspaceDocuments(workspaceId, participantId);
  const mode = useWorkspaceDocuments(workspaceId, participantId).mode;

  const setMode = useCallback(
    (mode: DocumentListAction) => {
      dispatch(actionSetDocumentListAction({ mode }));
    },
    [dispatch]
  );

  const lodgementCaseDetails: LodgementCaseDetail[] = useMemo(() => {
    const groupedDocumentsByLodgementCaseId = groupDocumentsByLodgementCaseId(workspaceDocumentsState.items);

    const lodgementCaseDetailModels: LodgementCaseDetail[] = [];
    for (let [key, value] of groupedDocumentsByLodgementCaseId) {
      let lodgementCaseDetail = workspaceDetailState.detail?.lodgementCases.find(x => x.id === key);
      lodgementCaseDetailModels.push({
        lodgementCase: lodgementCaseDetail,
        documents: value.map(document =>
          resolveDocumentListItem101(
            mode,
            document,
            workspaceId,
            participantId,
            documentId,
            workspaceDetailState.detail?.lodgementDetails.find(x => x.lodgementCaseId === key)?.dateTime,
            !lodgementCaseDetail?.isPrimary && workspaceDetailState.detail?.workspaceType.id !== WorkspaceTypeEnum.RegistrationOnly
          )
        )
      });
    }

    // sort by the lodgement case created date
    sortingByLodgementCase(lodgementCaseDetailModels);

    return lodgementCaseDetailModels;
  }, [
    mode,
    workspaceId,
    participantId,
    documentId,
    workspaceDocumentsState.items,
    workspaceDetailState.detail?.lodgementDetails,
    workspaceDetailState.detail?.lodgementCases,
    workspaceDetailState.detail?.workspaceType
  ]);

  if (workspaceDocumentsState.isLoading) {
    return (
      <Box>
        <ListLoader lineCount={2} />
      </Box>
    );
  }

  if (workspaceDocumentsState.status === 'rejected') {
    return (
      <Box>
        <FormHelperText role="alert" error={true}>
          {workspaceDocumentsState.error}
        </FormHelperText>
      </Box>
    );
  }

  const isToolbarEnabled: boolean = isToolbarAvailableForWorkspace({
    mode,
    // basic workspace info
    workspaceStatusId: workspaceBasicInfoState.detail?.workspaceStatusId,
    // full workspace info
    canAddAdditionalDocuments: workspaceDetailState.detail?.canAddAdditionalDocuments
  });

  return (
    <WorkspaceExpansionBox
      collapsedByDefault={collapsedByDefault}
      onExpandChange={onExpandChange}
      title={<DocumentListTitle mode={mode} workspaceId={workspaceId} participantId={participantId} />}
    >
      {lodgementCaseDetails.length <= 1 ? ( // keep the logic same for existing ws as it is only have one LC
        <DocumentList //
          //route props
          workspaceId={workspaceId}
          participantId={participantId}
          documentId={documentId}
          // additional workspace info
          workspaceTypeId={workspaceBasicInfoState.detail?.workspaceTypeId}
          // lodgement case and documents info
          lodgementCaseDetail={lodgementCaseDetails[0]}
          // other
          mode={mode}
          onModeChange={setMode}
          isToolbarEnabled={isToolbarEnabled}
        />
      ) : (
        <DocumentListMLC //
          //route props
          workspaceId={workspaceId}
          participantId={participantId}
          documentId={documentId}
          // additional workspace info
          workspaceTypeId={workspaceBasicInfoState.detail?.workspaceTypeId}
          // workspace document items
          lodgementCases={lodgementCaseDetails}
          // other
          mode={mode}
          onModeChange={setMode}
          isToolbarEnabled={isToolbarEnabled}
        />
      )}
    </WorkspaceExpansionBox>
  );
}

export default React.memo(DocumentListContainer);
