import * as React from 'react';

import classNames from 'classnames';
import { Action } from 'redux';
import Badge from '@mui/material/Badge';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import withStyles, { WithStyles } from '@mui/styles/withStyles';

import { JurisdictionsEnum, LodgementCaseStatusEnum, ParticipantStatusEnum, UserLevelPermissionEnum, WorkspaceStatusEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { WorkspaceParticipantApiResponse, WorkspaceReportApiResponse } from '@sympli/api-gateway/models';
import { LodgementCase, LodgementCompliance } from '@sympli/api-gateway/shared';
import FlexLayout from '@sympli/ui-framework/components/layout/flex-layout';
import { MessageNotificationContainer } from '@sympli/ui-framework/components/message-notification';

import { Box } from 'src/components/layout';
import { LineLoader } from 'src/components/loaders';
import Secured from 'src/containers/shared/auth/profile-security/Secured';
import { canWorkspaceBeArchived } from 'src/containers/shared/helper';
import { LinkedWorkspaceApiResponseModel } from 'src/containers/shared/linked-workspace-list/models';
import ParticipantList from 'src/containers/shared/participant-list';
import LodgementErrorMessageNotificationContainer from 'src/containers/workspace/shared/components/lodgement-error-message-notification';
import StatusHeader from 'src/containers/workspace/shared/detail/components/status-header';
import { convertComplianceToMessageGridModel, resolveBackLink } from 'src/containers/workspace/shared/detail/helpers';
import WorkspacePageContentWrapper from 'src/containers/workspace/shared/WorkspacePageContentWrapper';
import { SafeDispatch } from 'src/hooks/useSafeDispatch';
import { createRegroupIntoErrorsAndWarningsSelector } from '../../financial/detail/components/error-message-panel/selectors';
import ManageWorkspaceBoxContainer from '../../shared/components/manage-workspace/ManageWorkspaceBoxContainer';
import TabLinkedWorkspacesContainer from '../../shared/components/tab-linked-workspaces';
import WorkspaceFileDeleteDialog from '../../shared/components/workspace-file-delete-dialog';
import WorkspaceFileUploadDialog from '../../shared/components/workspace-file-upload-dialog';
import WorkspaceFilesContainer from '../../shared/components/workspace-files-container';
import { WorkspaceFile } from '../../shared/components/workspace-files/models';
import RisTacNotification from '../../shared/detail/components/ris-tac-notification/RisTacNotification';
import TabLogsContainer from '../../shared/detail/components/tab-logs';
import WorkspaceReportContainer from '../../shared/detail/components/tab-workspace-report';
import { WorkspaceBasicInfoState } from '../../shared/detail/reducers/workspaceBasicInfo';
import { WorkspaceTasksState } from '../../shared/detail/reducers/workspaceTasks';
import { WorkspaceIssuesModel } from '../../shared/detail/selectors';
import { ChangedItem } from '../../titles/models';
import TabTasksContainer from './components/tab-tasks';
import { isLodgementProgressVisible } from './components/tab-tasks/components/lodgement-progress';
import TabTasksV2 from './components/tab-tasks/v2/TabTasks';
import {
  resolveWorkspaceProgressNotificationPanelTypeForPLC,
  WorkspaceProgressNotificationPanel,
  WorkspaceProgressNotificationPanelBackground,
  WorkspaceProgressPanelNotificationTypeEnum
} from './components/workspace-progress-notification-panel';
import styles, { ClassKeys } from './styles';

const regroupIntoErrorsAndWarningsSelector = createRegroupIntoErrorsAndWarningsSelector<LodgementCompliance>();
export interface OwnProps {
  // route props
  workspaceId: string;
  participantId: string;
  // loader
  isLoadingWorkspaceBasicInfo: boolean;
  isLoadingWorkspaceParticipants: boolean;
  isLoadingWorkspaceTasks: boolean;
  isLoadingWorkspaceReports: boolean;
  isLoadingLinkedWorkspaces: boolean;

  // data
  workspaceDetail: {
    // basic workspace info
    lodgementCaseStatusId?: LodgementCaseStatusEnum;
    workspaceStatusId?: WorkspaceStatusEnum;
    workspaceTypeId?: WorkspaceTypeEnum;
    jurisdictionId?: JurisdictionsEnum;
    isLocked?: boolean;
    // full workspace info
    isAutoTacCeased?: boolean;
    titleReferenceRisChangedSet: ChangedItem[];
    titleReferenceTacChangedSet: ChangedItem[];
    // participant related details about workspace
    matter?: string;
    lodgementCase?: LodgementCase[];
  };
  isAbandonedWorkspace?: boolean;
  cacheFallback?: WorkspaceBasicInfoState['cacheFallback'];
  // current participant info
  isWorkspaceParticipant: boolean;
  // list of current workspace participants
  workspaceParticipants: WorkspaceParticipantApiResponse[];
  // list of linked workspaces
  linkedWorkspaces: LinkedWorkspaceApiResponseModel[];
  // list of reports
  workspaceReports: WorkspaceReportApiResponse[];
  // list of tasks for current participant
  workspaceTasksState: WorkspaceTasksState;
  // profile data
  profile: {
    userId: string;
    subscriberId: string;
  };
  workspaceIssues: WorkspaceIssuesModel;
  // other
  dispatch: SafeDispatch<Action>;
  // feature flags
  isMLCEnabled?: boolean;
}

type Props = OwnProps & WithStyles<ClassKeys>;

enum TabEnum {
  Tasks = 0,
  Logs = 1,
  Reports = 2,
  LinkedWorkspaces = 3
}

interface State {
  selectedTab: TabEnum;
  openFileUploadDialog: boolean;
  openDeleteFileDialog: boolean;
  selectedFile: WorkspaceFile | null;
}

class LodgementDetail extends React.PureComponent<Props, State> {
  public readonly state: Readonly<State> = {
    selectedTab: TabEnum.Tasks,
    openFileUploadDialog: false,
    openDeleteFileDialog: false,
    selectedFile: null
  };

  static getDerivedStateFromProps(props, state) {
    // Any time the current user changes,
    // Reset any parts of state that are tied to that user.
    // In this simple example, that's just the email.
    if (props.isAbandonedWorkspace && state.selectedTab !== TabEnum.Logs) {
      return {
        selectedTab: TabEnum.Logs
      };
    }
    return null;
  }

  render() {
    return (
      <>
        <MessageNotificationContainer>
          {this.renderRisNotification()}
          {this.renderTacNotification()}
        </MessageNotificationContainer>
        {this.renderMessages()}
        <WorkspacePageContentWrapper>
          {this.renderMatterHeader()}
          {this.renderFileUpload()}
          {this.renderDeleteFile()}
          {this.renderTabsNavigation()}
          {this.renderSelectedTabContent()}
        </WorkspacePageContentWrapper>
      </>
    );
  }

  private renderMessages() {
    const {
      participantId,
      workspaceId,
      workspaceDetail,
      workspaceIssues: {
        //
        lodgementCompliances
      }
    } = this.props;

    // group info and warning messages together
    const { errors, warnings } = regroupIntoErrorsAndWarningsSelector(lodgementCompliances);
    const lodgementComplianceId = lodgementCompliances?.length ? lodgementCompliances[0].complianceId : undefined;

    return (
      <LodgementErrorMessageNotificationContainer
        participantId={participantId}
        workspaceId={workspaceId}
        errors={errors.map(convertComplianceToMessageGridModel)}
        warnings={warnings.map(convertComplianceToMessageGridModel)}
        isAutoTacCeased={workspaceDetail.isAutoTacCeased}
        lodgementComplianceId={lodgementComplianceId}
      />
    );
  }

  private renderRisNotification() {
    const { participantId, workspaceId, workspaceDetail, profile } = this.props;

    if (!Number.isInteger(workspaceDetail.workspaceStatusId)) {
      return null;
    }

    return (
      <RisTacNotification
        workspaceId={workspaceId}
        participantId={participantId}
        changedSet={workspaceDetail.titleReferenceRisChangedSet}
        userId={profile.userId}
        workspaceStatus={workspaceDetail.workspaceStatusId!}
        notificationType="Ris_Notification_WorkspaceOverview"
      />
    );
  }

  private renderTacNotification() {
    const { participantId, workspaceId, profile, workspaceDetail } = this.props;

    if (!Number.isInteger(workspaceDetail.workspaceStatusId)) {
      return null;
    }

    return (
      <RisTacNotification
        workspaceId={workspaceId}
        participantId={participantId}
        changedSet={workspaceDetail.titleReferenceTacChangedSet}
        userId={profile.userId}
        workspaceStatus={workspaceDetail.workspaceStatusId!}
        notificationType="Tac_Notification_WorkspaceOverview"
      />
    );
  }

  private renderMatterHeader() {
    const {
      //
      classes,
      isLoadingWorkspaceBasicInfo,
      isLoadingWorkspaceParticipants,
      workspaceDetail,
      workspaceId,
      participantId,
      dispatch
    } = this.props;

    // we need to wait for this in order to tell whether user is current participant or not
    // in LO it's enough to wait for workspace basic info (see implementation of isCurrentUserWorkspaceParticipantSelector)
    if (isLoadingWorkspaceBasicInfo) {
      return (
        <div className={classes.headerLoaderContainer}>
          <LineLoader color="white" variant="small" widthPercent={100} classes={{ root: classes.headerLoader }} />
        </div>
      );
    }

    // we loaded all necessary info and user is not participant, we don't show matter reference
    if (!this.props.isWorkspaceParticipant) {
      return null;
    }

    // we need to wait for this in order to get matter reference
    if (!this.props.workspaceDetail.matter && isLoadingWorkspaceParticipants) {
      return (
        <div className={classes.headerLoaderContainer}>
          <LineLoader color="white" variant="small" widthPercent={100} classes={{ root: classes.headerLoader }} />
        </div>
      );
    }

    return (
      <StatusHeader
        // route params
        workspaceId={workspaceId}
        participantId={participantId}
        // basic workspace info
        isLocked={workspaceDetail.isLocked}
        workspaceStatus={workspaceDetail.workspaceStatusId!}
        // current participant
        // use fallback mechanism in case we have data from dashboard
        reference={this.props.workspaceDetail.matter}
        dispatch={dispatch}
      />
    );
  }

  private renderFileUpload() {
    const { openFileUploadDialog, selectedFile } = this.state;
    const { workspaceId, participantId, workspaceParticipants, workspaceDetail } = this.props;

    return (
      <WorkspaceFileUploadDialog
        workspaceId={workspaceId}
        participantId={participantId}
        participants={workspaceParticipants}
        editFile={selectedFile}
        openDialog={openFileUploadDialog}
        onClose={this.handleUploadFileDialogClose}
        workspaceType={workspaceDetail.workspaceTypeId}
      />
    );
  }

  private renderDeleteFile() {
    const { openDeleteFileDialog, selectedFile } = this.state;
    const { workspaceId, participantId } = this.props;

    return selectedFile ? (
      <WorkspaceFileDeleteDialog
        workspaceId={workspaceId}
        participantId={participantId}
        deleteFile={selectedFile}
        openDialog={openDeleteFileDialog}
        onClose={this.handleDeleteFileDialogClose}
      />
    ) : null;
  }

  private handleOnSelectReportsTab = () => {
    this.setState({ selectedTab: TabEnum.Reports });
  };

  private renderTabHeader(args: {
    //
    isLoading?: boolean;
    value: TabEnum;
    label: string;
    badgeCount?: number;
  }) {
    const { classes } = this.props;
    // loader takes priority
    if (args.isLoading) {
      return (
        <Tab //
          label={<LineLoader variant="small" icon widthPercent={100} />}
          value={args.value}
          classes={{ root: classes.tab }}
          disabled
        />
      );
    }

    const labelJsx = args.badgeCount ? (
      <Badge classes={{ badge: classes.badge, root: classes.baselineAligned }} badgeContent={args.badgeCount} color="primary">
        {args.label}
      </Badge>
    ) : (
      args.label
    );

    return (
      <Tab //
        data-testid={`workspace-tab-${args.label.toLowerCase()}`}
        label={labelJsx}
        value={args.value}
        classes={{ root: classes.tab }}
      />
    );
  }

  private resolveFirstTab() {
    const { isLoadingWorkspaceBasicInfo, workspaceDetail, workspaceTasksState } = this.props;

    if (isLoadingWorkspaceBasicInfo) {
      return this.renderTabHeader({
        label: '', // does not matter what we have here, it will show loader instead of text anyway
        value: TabEnum.Tasks,
        isLoading: true
      });
    }

    const isLodgementProgressTab: boolean = isLodgementProgressVisible(workspaceDetail.workspaceStatusId, workspaceDetail.lodgementCaseStatusId);
    if (isLodgementProgressTab) {
      return this.renderTabHeader({
        label: 'Lodgement progress',
        value: TabEnum.Tasks,
        isLoading: false
      });
    }

    return this.renderTabHeader({
      label: 'Actions',
      value: TabEnum.Tasks,
      isLoading: false,
      badgeCount: workspaceTasksState.items.length
    });
  }

  private renderTabsNavigation() {
    const {
      // classes,
      isLoadingWorkspaceBasicInfo,
      workspaceReports,
      linkedWorkspaces
    } = this.props;

    // in abandoned workspace user can see only log
    if (this.props.isAbandonedWorkspace) {
      return (
        <Tabs value={TabEnum.Logs} onChange={this.handleTabChange} indicatorColor="primary" textColor="inherit">
          {this.renderTabHeader({
            label: 'Log',
            value: TabEnum.Logs,
            isLoading: false // no need to have loader here, since we explicitly know there will be Log tab only
          })}
        </Tabs>
      );
    }

    return (
      <Tabs value={this.state.selectedTab} onChange={this.handleTabChange} indicatorColor="primary" textColor="inherit">
        {this.resolveFirstTab()}
        {this.renderTabHeader({
          label: 'Log',
          value: TabEnum.Logs,
          isLoading: isLoadingWorkspaceBasicInfo // make this tab to look like it's loading while we are still resolving default tab
        })}
        {Boolean(workspaceReports.length) && // if we don't have any reports, we don't display them
          this.renderTabHeader({
            label: 'Records',
            value: TabEnum.Reports,
            isLoading: false, // keep this tab always hidden until we load some items
            badgeCount: workspaceReports.length
          })}
        {Boolean(linkedWorkspaces.length) &&
          this.renderTabHeader({
            // keep this tab always hidden until we load some items
            label: 'Linked workspaces',
            value: TabEnum.LinkedWorkspaces,
            isLoading: false,
            badgeCount: linkedWorkspaces.length
          })}
      </Tabs>
    );
  }

  private renderWorkspaceProgressNotificationPanels() {
    const {
      //
      workspaceDetail: {
        //
        workspaceStatusId,
        lodgementCaseStatusId: primaryLodgementCaseStatusId
      }
    } = this.props;

    if (!Number.isInteger(workspaceStatusId)) {
      return null;
    }

    // calculate notification panel for primary lodgement case
    const notificationTypeForPLC: WorkspaceProgressPanelNotificationTypeEnum | undefined = resolveWorkspaceProgressNotificationPanelTypeForPLC({
      //
      workspaceStatusId: workspaceStatusId!,
      lodgementCaseStatusId: primaryLodgementCaseStatusId
    });

    const showPLCNotification = notificationTypeForPLC !== undefined;

    if (!showPLCNotification) {
      return null;
    }
    return (
      // workspace progress notification panel needs to stay under
      // global search(zIndex-4), top panel(zIndex-4), simple toast message(zIndex - 4)
      // other notification(zIndex - 3)
      <div className="z-[2] mb-8 flex flex-col gap-4">
        {showPLCNotification && (
          <>
            <WorkspaceProgressNotificationPanelBackground //
              notificationType={notificationTypeForPLC}
            />
            <WorkspaceProgressNotificationPanel //
              className="z-10"
              notificationType={notificationTypeForPLC}
            />
          </>
        )}
      </div>
    );
  }

  private get isTaskSectionAccessible() {
    // TODO implement correct condition where we should enable/disable task section
    return true;
  }

  private renderSelectedTabContent() {
    const {
      //
      classes,
      workspaceId,
      participantId,
      workspaceDetail,
      workspaceReports,
      workspaceParticipants,
      isMLCEnabled,
      isLoadingWorkspaceBasicInfo,
      workspaceTasksState,
      dispatch
    } = this.props;

    switch (this.state.selectedTab) {
      case TabEnum.Tasks:
        return (
          <div className="relative flex flex-col">
            {isMLCEnabled && this.renderWorkspaceProgressNotificationPanels()}
            <FlexLayout alignItems="flex-start">
              <FlexLayout className={classes.taskTabWrapper} flexDirection="column">
                <Box className={classNames(!this.isTaskSectionAccessible && 'opacity-40')}>
                  <div className={classNames('flex-grow', !this.isTaskSectionAccessible && 'pointer-events-none')}>
                    {isMLCEnabled ? (
                      <TabTasksV2 //
                        // route params
                        workspaceId={workspaceId}
                        participantId={participantId}
                        // redux
                        // basic info
                        workspaceStatusId={workspaceDetail.workspaceStatusId}
                        lodgementCaseStatusId={workspaceDetail.lodgementCaseStatusId}
                        // tasks
                        workspaceTasksState={workspaceTasksState}
                        // other
                        isLoadingWorkspace={isLoadingWorkspaceBasicInfo} // track only first load
                        backLink={resolveBackLink(workspaceDetail.workspaceTypeId).linkTo}
                        onTaskAction={dispatch}
                      />
                    ) : (
                      <TabTasksContainer
                        workspaceId={workspaceId}
                        participantId={participantId}
                        backLink={resolveBackLink(workspaceDetail.workspaceTypeId).linkTo}
                        onSelectReportsTab={workspaceReports.length ? this.handleOnSelectReportsTab : undefined}
                      />
                    )}
                  </div>
                </Box>
                <WorkspaceFilesContainer //
                  workspaceId={workspaceId}
                  participantId={participantId}
                  participants={workspaceParticipants}
                  disabled={workspaceDetail.isLocked}
                  onUploadFile={this.handleUploadFileClick}
                  onEditFile={this.handleEditFileClick}
                  onDeleteFile={this.handleDeleteFileClick}
                />
              </FlexLayout>

              <FlexLayout flexDirection="column" className={classes.rightPanel}>
                {this.renderAcceptedParticipants()}
                {this.renderAdditionalOptions()}
              </FlexLayout>
            </FlexLayout>
          </div>
        );

      case TabEnum.Logs:
        return this.props.isAbandonedWorkspace ? (
          this.renderLogTab()
        ) : (
          <FlexLayout>
            <FlexLayout className={classes.taskTabWrapper} flexDirection="column">
              {this.renderLogTab()}
            </FlexLayout>
            <FlexLayout flexDirection="column" className={classes.rightPanel}>
              {this.renderAcceptedParticipants()}
              {this.renderAdditionalOptions()}
            </FlexLayout>
          </FlexLayout>
        );
      case TabEnum.Reports:
        return (
          <WorkspaceReportContainer //
            workspaceId={workspaceId}
            participantId={participantId}
          />
        );
      case TabEnum.LinkedWorkspaces:
        return (
          <TabLinkedWorkspacesContainer //
            workspaceId={workspaceId}
            participantId={participantId}
          />
        );
      default:
        return 'notes TODO';
    }
  }

  private renderTabWithDetail = (renderMainContent: () => JSX.Element) => {
    const {
      //
      classes
    } = this.props;

    return (
      <FlexLayout>
        <FlexLayout className={classes.taskTabWrapper} flexDirection="column">
          {renderMainContent()}
        </FlexLayout>
        <FlexLayout flexDirection="column" className={classes.rightPanel}>
          {this.renderAcceptedParticipants()}
          {this.renderAdditionalOptions()}
        </FlexLayout>
      </FlexLayout>
    );
  };

  private renderTaskTab = () => {
    const {
      //
      workspaceId,
      participantId,
      workspaceDetail,
      workspaceReports,
      workspaceParticipants
    } = this.props;

    return (
      <>
        <TabTasksContainer
          workspaceId={workspaceId}
          participantId={participantId}
          backLink={resolveBackLink(workspaceDetail.workspaceTypeId).linkTo}
          onSelectReportsTab={workspaceReports.length ? this.handleOnSelectReportsTab : undefined}
        />
        <WorkspaceFilesContainer //
          workspaceId={workspaceId}
          participantId={participantId}
          participants={workspaceParticipants}
          disabled={workspaceDetail.isLocked}
          onUploadFile={this.handleUploadFileClick}
          onEditFile={this.handleEditFileClick}
          onDeleteFile={this.handleDeleteFileClick}
        />
      </>
    );
  };

  private renderLogTab = () => {
    const {
      //
      workspaceId,
      participantId
    } = this.props;

    return (
      <TabLogsContainer //
        workspaceId={workspaceId}
        participantId={participantId}
      />
    );
  };

  private renderAcceptedParticipants() {
    const {
      //
      workspaceId,
      participantId,
      workspaceParticipants,
      isLoadingWorkspaceParticipants,
      classes
    } = this.props;

    const acceptedParticipants = workspaceParticipants.filter(p => p.participantStatus.id === ParticipantStatusEnum.Accepted);

    return (
      <Box //
        title="Participants"
        disabled={isLoadingWorkspaceParticipants}
        className={classes.box}
      >
        <ParticipantList //
          dataTestId={`workspace-accepted-participants-${acceptedParticipants.length}`}
          workspaceId={workspaceId}
          participantId={participantId}
          isLoading={isLoadingWorkspaceParticipants}
          // list of participants that accepted invite
          items={acceptedParticipants}
          // other
          loaderBackgroundColor="white"
        />
      </Box>
    );
  }

  private renderAdditionalOptions() {
    const {
      //
      workspaceId,
      participantId,

      workspaceDetail: { isLocked, jurisdictionId, lodgementCaseStatusId, workspaceStatusId, workspaceTypeId, lodgementCase },
      workspaceParticipants,
      classes
    } = this.props;

    if (![jurisdictionId, lodgementCaseStatusId, workspaceStatusId, workspaceTypeId].every(Number.isInteger)) {
      return null;
    }

    if (workspaceStatusId === WorkspaceStatusEnum.Archived || workspaceStatusId === WorkspaceStatusEnum.Abandoned || !workspaceParticipants.length) {
      return null;
    }

    // when workspace is locked, usually we don't show additional options, but there is exception,
    // like a settled FS workspace or a completed S workspace, they both wait to be archived
    if (isLocked && !canWorkspaceBeArchived(lodgementCaseStatusId, workspaceStatusId)) {
      return null;
    }

    return (
      <Secured requiredUserPermissions={UserLevelPermissionEnum.ManageInvitations} hiddenForDisabled>
        <Box //
          title="Additional Options"
          className={classes.box}
        >
          <ManageWorkspaceBoxContainer //
            // route params
            workspaceId={workspaceId}
            participantId={participantId}
            // redux data
            workspaceStatusId={workspaceStatusId!}
            workspaceTypeId={WorkspaceTypeEnum.RegistrationOnly}
            lodgementCases={lodgementCase ?? []}
          />
        </Box>
      </Secured>
    );
  }

  private handleTabChange = (_unusedEvent: any, value: TabEnum) => {
    this.setState({ selectedTab: value });
  };

  private handleUploadFileClick = () => {
    this.setState({ selectedFile: null, openFileUploadDialog: true });
  };

  private handleUploadFileDialogClose = () => {
    this.setState({ openFileUploadDialog: false });
  };

  private handleEditFileClick = (file: WorkspaceFile) => {
    this.setState({ selectedFile: file, openFileUploadDialog: true });
  };

  private handleDeleteFileClick = (file: WorkspaceFile) => {
    this.setState({ selectedFile: file, openDeleteFileDialog: true });
  };

  private handleDeleteFileDialogClose = () => {
    this.setState({ openDeleteFileDialog: false });
  };
}

const styledComponent = withStyles(styles)(LodgementDetail);
export default styledComponent;
