import {
  InvitationStatusEnum,
  JurisdictionsEnum,
  ParticipantArchivedStatusEnum,
  SettlementDateKindEnum,
  WorkspaceRoleEnum,
  WorkspaceStatusEnum,
  WorkspaceTransactionTypeEnum,
  WorkspaceTypeEnum
} from '@sympli/api-gateway/enums';
import { DateTimeInfo, WorkspaceDateTimeModel } from '@sympli/api-gateway/shared';
import { LookupItemModel } from '@sympli/ui-framework/models';

import { ScreenSizeVariant } from 'src/theme/screens';
import { InvitationsDashboardRouteTabsEnumV2 } from '../models';

/**
 * Tickle.Application.Domain.Dashboard.V2.InvitationDashboardRecord
 */
interface InvitationDashboardRecord {
  workspaceId: string;
  participantId: string; // this is also used as invitationId
  workspaceTypeId: WorkspaceTypeEnum;
  invitationStatus: InvitationStatusEnum;
  lastInvitationSentDate: DateTimeInfo;
  sympliId: string;
  titleReferences: InvitationTitle[];
  matter?: string;
  jurisdictionId: JurisdictionsEnum;
  roles: string[];
  groupName?: string;
  member?: string;
  sentBy?: string;
  fromSubscriberName: string;
  toSubscriberName: string;
  invitationDescription?: string;
  proposedSettlementDate?: WorkspaceDateTimeModel;
  expectedSettlementDate?: ExpectedSettlementDate;
  archivedDate?: DateTimeInfo;
  originalInvitationLastSentDate?: DateTimeInfo;
  originalInvitationId?: string;
  type: ParticipantArchivedStatusEnum;
  canResend?: boolean;
  invitedByParticipantId: string;
  heroWorkspace?: HeroWorkspaceModel;
  participantRoles: LookupItemModel<WorkspaceRoleEnum>[];
  forwardedFromInvitationId?: string;
  forwardedFromSubscriberName?: string;
  forwardedToInvitationId?: string;
  forwardedToSubscriberName?: string;
  invitationTransactionType?: WorkspaceTransactionTypeEnum;
  invitationTransactionTypeOtherDescription?: string;
  workspaceStatusId?: WorkspaceStatusEnum;
  workspaceIsLocked?: boolean;
  isUnsupportedSettlementDate?: boolean;
}

export interface InvitationTitle {
  reference: string;
  address: {
    addressLine1: string;
    addressLine2?: string;
  };
  proprietors: Array<{
    legalEntityName: string;
  }>;
}

export interface ExpectedSettlementDate {
  workspaceDateTime: WorkspaceDateTimeModel;
  settlementDateKind: SettlementDateKindEnum;
}

export interface HeroWorkspaceModel {
  workspaceId: string;
  participantId: string;
  sympliId: string;
}

export type InvitationsTableRowModel = InvitationDashboardRecord;

export enum SortingFieldEnum {
  LastInvitationSentDate = 0,
  LastInvitationStatusUpdatedDate = 1
}

export enum SortingOptionEnum {
  SettlementDate = 'settlementDate',
  LastUpdated = 'lastUpdated',
  LastAccessed = 'lastAccessed'
}

/**
 * Sympli.DataService.Client.Model.InvitationTypeFilter
 */
export enum DashboardInvitationTypeFilterEnum {
  Received = 0,
  Sent = 1,
  Archived = 2
}

export enum InvitationArchivedTypeEnum {
  Received = 0,
  Sent = 1
}

export enum DashboardInvitationStatusFilterEnum {
  Accepted = 0,
  Declined = 1,
  Forwarded = 2,
  Withdrawn = 3
}

export interface InvitationsTableContext {
  screenVariant: ScreenSizeVariant;
  selectedTab: InvitationsDashboardRouteTabsEnumV2;
}

export enum ArchivedInvitationLogsRoleEnum {
  Forwarder = 0,
  OriginalSender = 1,
  LastReceiver = 2
}

export enum ArchivedInvitationLogsActionEnum {
  Accepted = 0,
  Declined = 1,
  Withdrawn = 2,
  Forwarded = 3,
  Sent = 4
}
