import * as React from 'react';

import { useDispatch } from 'react-redux';
import { Location, useLocation, useNavigate } from 'react-router-dom';

import { WorkspaceTypeEnum } from '@sympli/api-gateway/enums';

import { actionOpenGlobalSimpleNotification } from 'src/components/global-simple-notification/actions';
import GeneralCrashedContent from 'src/containers/app-crashed-page/components/content';
import FinancialDetailContainer from 'src/containers/workspace/financial/detail';
import LodgementDetailContainer from 'src/containers/workspace/registration-only/detail';
import { useWorkspaceBasicInfo } from 'src/containers/workspace/shared/detail/reducers/workspaceBasicInfo';
import { useWorkspaceDetail } from 'src/containers/workspace/shared/detail/reducers/workspaceDetail';
import { useRouterParams, useSafeDispatch } from 'src/hooks';
import WorkspaceDetailPageLoader from './workspace-detail-page-loader';

export interface WorkspaceDetailRouteParams {
  workspaceId: string;
  participantId: string;
}

export interface WorkspaceDetailRouteLocationState {
  message: string;
  secondaryMessage?: string;
}

interface TypedLocation extends Location {
  state: WorkspaceDetailRouteLocationState;
}

export default function WorkspaceDetailPageContainer() {
  const { workspaceId, participantId } = useRouterParams<WorkspaceDetailRouteParams>();

  const navigate = useNavigate();
  const dispatch = useSafeDispatch(useDispatch());
  const { state, pathname, search } = useLocation() as TypedLocation;
  const workspaceBasicInfoState = useWorkspaceBasicInfo(workspaceId);
  const workspaceDetailState = useWorkspaceDetail(workspaceId, participantId);

  React.useEffect(() => {
    if (state?.message) {
      dispatch(
        actionOpenGlobalSimpleNotification({
          //
          message: state.message,
          secondaryMessage: state.secondaryMessage,
          autoHideDuration: 5000,
          variant: 'new-success'
        })
      );
      // cleanup location state
      // this will cause componentDidUpdate to be triggered, so it's important to prevent unnecessary re-fetch/re-render
      // history.replace({ ...history.location, state: undefined });
      navigate(pathname + search, { state: undefined, replace: true });
    }
  }, [dispatch, navigate, pathname, search, state]);

  if (workspaceBasicInfoState.error || workspaceDetailState.error) {
    return <GeneralCrashedContent />;
  }

  switch (workspaceBasicInfoState.detail?.workspaceTypeId) {
    case WorkspaceTypeEnum.FinancialSettlement:
      return (
        <FinancialDetailContainer //
          workspaceId={workspaceId}
          participantId={participantId}
        />
      );
    case WorkspaceTypeEnum.RegistrationOnly:
      // fallback to here even if we don't know workspace type yet
      return (
        <LodgementDetailContainer //
          workspaceId={workspaceId}
          participantId={participantId}
        />
      );
    default:
      return <WorkspaceDetailPageLoader />;
  }
}
