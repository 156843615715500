import * as React from 'react';

import { FormikProps } from 'formik';
import { useDispatch } from 'react-redux';

import { WorkspaceDirectionsApiResponse } from '@sympli/api-gateway/models';
import BlockLoader from '@sympli/ui-framework/components/loaders/block-loader';

import { GeneralAccountUsageApiResponse } from 'src/containers/settings/subscriber-profile/financial-accounts/components/general-account-detail/models';
import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import { getGeneralAccountUsages as getGeneralAccountUsage } from 'src/containers/workspace/financial/directions/api';
import DirectionsContext from 'src/containers/workspace/financial/directions/Directions.context';
import { TrustAccountMap } from 'src/containers/workspace/financial/directions/models';
import { useDirectionsCategories } from 'src/containers/workspace/financial/directions/reducers/categories';
import { actionFetchWorkspaceFinancialAccounts } from 'src/containers/workspace/shared/detail/actions';
import { useWorkspaceFinancialAccounts } from 'src/containers/workspace/shared/detail/reducers/workspaceDebitAccountsFeed';
import { useFeatureFlag, useSafeDispatch } from 'src/hooks';
import { WorkspaceDetailRouteParams } from 'src/pages/workspace/detail/WorkspaceDetailPageContainer';
import { DischargeDirectionsFormModel } from '../models';
import MortgageEditor from './MortgageEditor';

interface Props {
  queryParams: WorkspaceDetailRouteParams;
  formikProps: FormikProps<DischargeDirectionsFormModel>;
  index: number;
  detail: WorkspaceDirectionsApiResponse;
  arrayFieldName?: string;
  workspaceId: string;
  participantId: string;
  trustAccountMap: TrustAccountMap;
}

function MortgageEditorContainer({
  //
  queryParams,
  ...rest
}: Props) {
  const [usage, setUsage] = React.useState<GeneralAccountUsageApiResponse>([]);
  const { setFocusLabel } = React.useContext(DirectionsContext);
  const { isLoading, error, detail: directionsCategoriesDetail } = useDirectionsCategories(queryParams.workspaceId, queryParams.participantId);
  const dispatch = useSafeDispatch(useDispatch());
  const { items: financialAccounts } = useWorkspaceFinancialAccounts();
  const restrictManualAccountDetailsEntryEnabled: boolean = useFeatureFlag(FeatureToggleEnum.restrictManualAccountDetailsEntry);
  const isFssLineItemSaveEnabled = useFeatureFlag(FeatureToggleEnum.fssLineItemSaveEnabled);

  React.useEffect(() => {
    dispatch(actionFetchWorkspaceFinancialAccounts.request());
  }, [dispatch]);

  React.useEffect(() => {
    getGeneralAccountUsage() //
      .then(apiResponse => {
        setUsage(apiResponse);
      });
  }, [dispatch]);

  if (isLoading) {
    return <BlockLoader />;
  }

  if (!directionsCategoriesDetail || error) {
    return null;
  }

  return (
    <MortgageEditor //
      {...rest}
      directionsCategoriesDetail={directionsCategoriesDetail}
      usage={usage}
      financialAccounts={financialAccounts}
      restrictManualAccountDetailsEntryEnabled={restrictManualAccountDetailsEntryEnabled}
      isFssLineItemSaveEnabled={isFssLineItemSaveEnabled}
      dispatch={dispatch}
      setFocusLabel={setFocusLabel}
    />
  );
}

export default MortgageEditorContainer;
