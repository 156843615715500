import React from 'react';

import { DocumentPermissionEnum, DocumentStatusEnum, DocumentTypeIdentifierEnum, JurisdictionsEnum } from '@sympli/api-gateway/enums';
import { WorkspaceDocumentSummaryApiResponse, WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import { LodgementCase, WorkspaceDateTimeModel } from '@sympli/api-gateway/shared';

import OutsideOperationHoursMessageBox from 'src/containers/workspace/registration-only/detail/components/outside-operation-hours';
import { DocumentLodgementDetail } from '../shared/document-list/models';
import DocumentNotificationPanel from './components/document-notification-panel';
import { getDocumentPrerequisite, getFinancialWorkflowStep, getLodgeOnlyWorkflowStep, isActionPermitted } from './helpers';
import { useDocumentIssues, useWorkflowPanel } from './hooks';
import { DocumentFormAndDetailModel, DocumentPageRouteParams, DocumentWorkflowStepsEnum } from './models';
import { DocumentMergedPdfState } from './reducers/documentMergedPdf';
import EditDocument from './views/edit-document';
import ExternalDocument from './views/external-document';
import LodgeDocument from './views/lodge-document';
import LodgedDocument from './views/lodged-document';
import ReviewDocument from './views/review-document';
import SignDocument from './views/sign-document';
import ViewDocument from './views/view-document';

interface Props {
  profileUserId: string;
  isFinancial: boolean;
  queryParams: DocumentPageRouteParams;
  workspaceDetail: {
    // workspace basic info
    jurisdictionId: JurisdictionsEnum;
    // workspace detail
    workspaceLodgementDate?: WorkspaceDateTimeModel;
    // LRS data
    // this is required only for non financial workspaces
    isValidOperatingHoursForRegOnly?: boolean;
    lodgementCases?: LodgementCase[];
    isLocked?: boolean;
  };
  detail: DocumentFormAndDetailModel;
  documentList: WorkspaceDocumentSummaryApiResponse[];
  documentMergedPdfState: DocumentMergedPdfState;
  verifying: boolean;
  currentParticipant?: WorkspaceParticipantApiResponse;
  participants?: WorkspaceParticipantApiResponse[];
  lodgementDetail?: DocumentLodgementDetail;
  lodgementCase: LodgementCase;
}

function DocumentsPage({
  profileUserId,
  isFinancial,
  queryParams,
  workspaceDetail: {
    //
    jurisdictionId,
    workspaceLodgementDate,
    isValidOperatingHoursForRegOnly,
    isLocked
  },
  detail,
  documentList,
  documentMergedPdfState,
  verifying,
  currentParticipant,
  participants,
  lodgementDetail,
  lodgementCase
}: Props) {
  const { participantId, documentId } = queryParams;
  const {
    documentWorkflowType,
    status,
    documentPermissions,
    documentForm: { documentType },
    parsedData,
    lodgementCaseId
  } = detail;
  const getWorkflowStep = isFinancial && lodgementCase.isPrimary ? getFinancialWorkflowStep : getLodgeOnlyWorkflowStep;
  const displayDocumentAsPdf = !!(currentParticipant?.displayDocumentAsPdf || DocumentTypeIdentifierEnum.LodgementInstructions === documentType);

  const documentPrerequisite = React.useMemo(() => getDocumentPrerequisite(parsedData), [parsedData]);
  const documentIds = React.useMemo(() => documentList.map(d => d.documentId), [documentList]);
  const lodgementCaseDocuments = React.useMemo(() => documentList.filter(e => e.lodgementCaseId === lodgementCase.id), [documentList, lodgementCase.id]);

  // * We only want to get the initial workflow step based on document status
  // * Subsequent step changes will be controlled by document onPostSubmit handlers
  // * Default to Write (edit mode)
  const [workflowStep, setWorkflowStep] = React.useState<DocumentWorkflowStepsEnum>(
    () => getWorkflowStep(status, documentWorkflowType, lodgementCase.lodgementCaseStatusId, documentPermissions) ?? DocumentWorkflowStepsEnum.Write
  );

  const isSignStep = workflowStep === DocumentWorkflowStepsEnum.Sign;
  const disableNextButton = !isActionPermitted(workflowStep, documentPermissions);

  // memorize all the document compliances
  useDocumentIssues(participantId, workflowStep, documentList, detail, lodgementDetail);

  const updateWorkflowStep = React.useCallback(
    (status: DocumentStatusEnum) => {
      const nextWorkflowStep = getWorkflowStep(status, documentWorkflowType, lodgementCase.lodgementCaseStatusId, documentPermissions);

      if (nextWorkflowStep && workflowStep !== nextWorkflowStep) {
        setWorkflowStep(nextWorkflowStep);
      }
    },
    [documentPermissions, documentWorkflowType, getWorkflowStep, lodgementCase.lodgementCaseStatusId, workflowStep]
  );

  React.useEffect(() => {
    updateWorkflowStep(status);
    // * We only want to update workflow step based on document status for different documents
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId]);

  React.useEffect(() => {
    // we need to redirect to resolve issues step only if document status changed to ResolveIssues
    if (status === DocumentStatusEnum.ResolveIssues) {
      updateWorkflowStep(status);
    }
  }, [status, updateWorkflowStep]);

  React.useEffect(() => {
    const newWorkflowStep = getWorkflowStep(status, documentWorkflowType, lodgementCase.lodgementCaseStatusId);
    if (newWorkflowStep === DocumentWorkflowStepsEnum.Lodged && workflowStep !== DocumentWorkflowStepsEnum.Lodged) {
      setWorkflowStep(DocumentWorkflowStepsEnum.Lodged);
    }
    // * We need to make sure the step is changed to lodged when lodgementCaseStatusId is updated eg: background updated via realtime server
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lodgementCase.lodgementCaseStatusId]);

  const handleStepChange = (_: React.MouseEvent<HTMLButtonElement>, targetStep: DocumentWorkflowStepsEnum) => {
    if (workflowStep !== targetStep) {
      setWorkflowStep(targetStep);
    }
  };

  const workflowPanelProps = useWorkflowPanel(queryParams, workflowStep, detail, displayDocumentAsPdf, handleStepChange);

  function renderDocumentContent() {
    if (!workflowPanelProps) {
      return null;
    }

    switch (workflowStep) {
      case DocumentWorkflowStepsEnum.Resolve:
        return (
          <EditDocument //
            queryParams={queryParams}
            updateWorkflowStep={updateWorkflowStep}
            documentDetail={detail}
            jurisdictionId={jurisdictionId}
            currentParticipant={currentParticipant}
            participants={participants}
            numberOfDocuments={documentList.length}
            workflowPanelProps={workflowPanelProps}
          />
        );
      case DocumentWorkflowStepsEnum.Write:
        const hasPermissionToEdit = !!documentPermissions.includes(DocumentPermissionEnum.Write);
        const showPdf = hasPermissionToEdit ? false : displayDocumentAsPdf;
        return showPdf || documentPrerequisite?.isPrerequisiteNotReady === true ? (
          <ViewDocument //
            queryParams={queryParams}
            documentDetail={detail}
            jurisdictionId={jurisdictionId}
            workflowPanelProps={workflowPanelProps}
            updateWorkflowStep={updateWorkflowStep}
            documentIds={documentIds}
            displayDocumentAsPdf={showPdf}
            currentParticipant={currentParticipant}
          />
        ) : (
          <EditDocument //
            queryParams={queryParams}
            updateWorkflowStep={updateWorkflowStep}
            documentDetail={detail}
            jurisdictionId={jurisdictionId}
            currentParticipant={currentParticipant}
            participants={participants}
            numberOfDocuments={documentList.length}
            workflowPanelProps={workflowPanelProps}
          />
        );
      case DocumentWorkflowStepsEnum.Review:
        return (
          <ReviewDocument
            queryParams={queryParams}
            updateWorkflowStep={updateWorkflowStep}
            displayDocumentAsPdf={displayDocumentAsPdf}
            documentDetail={detail}
            jurisdictionId={jurisdictionId}
            documentIds={documentIds}
            workflowPanelProps={workflowPanelProps}
          />
        );
      case DocumentWorkflowStepsEnum.Read:
        return (
          <ExternalDocument
            queryParams={queryParams}
            displayDocumentAsPdf={displayDocumentAsPdf}
            disableNextButton={disableNextButton}
            documentDetail={detail}
            jurisdictionId={jurisdictionId}
            documentMergedPdfState={documentMergedPdfState}
          />
        );
      case DocumentWorkflowStepsEnum.Sign:
        if (!lodgementCase.isPrimary && status === DocumentStatusEnum.Signed) {
          return (
            <LodgeDocument
              queryParams={queryParams}
              disableLodge={!isValidOperatingHoursForRegOnly || lodgementCase.isLocked || isLocked || currentParticipant?.id !== lodgementCase.responsibleParticipantId}
              displayDocumentAsPdf={displayDocumentAsPdf}
              disableNextButton={disableNextButton}
              documentDetail={detail}
              jurisdictionId={jurisdictionId}
              verifying={verifying}
              documentList={lodgementCaseDocuments}
              documentMergedPdfState={documentMergedPdfState}
              workflowPanelProps={workflowPanelProps}
              lodgementCaseId={lodgementCaseId}
            />
          );
        }

        return (
          <SignDocument
            queryParams={queryParams}
            displayDocumentAsPdf={displayDocumentAsPdf}
            disableNextButton={disableNextButton}
            documentDetail={detail}
            documentMergedPdfState={documentMergedPdfState}
            jurisdictionId={jurisdictionId}
            workflowPanelProps={workflowPanelProps}
          />
        );
      case DocumentWorkflowStepsEnum.Lodged:
        return (
          <LodgedDocument //
            queryParams={queryParams}
            lodgedId={lodgementDetail?.lrDocumentId}
            lodgedDate={workspaceLodgementDate}
          />
        );
      default:
        return 'Unknown document workflow step';
    }
  }

  return (
    <>
      <DocumentNotificationPanel //
        profileUserId={profileUserId}
        currentWorkflowStep={workflowStep}
        queryParams={queryParams}
        detail={detail}
        documentType="document"
        workspaceDocuments={documentList}
        documentPrerequisite={documentPrerequisite}
      />
      {/* Only show this office hour warning when signing lodge only documents and non-plc doc*/}
      {!lodgementCase.isPrimary && isSignStep && !isValidOperatingHoursForRegOnly ? <OutsideOperationHoursMessageBox jurisdictionId={jurisdictionId} /> : null}
      {renderDocumentContent()}
    </>
  );
}

export default React.memo(DocumentsPage);
