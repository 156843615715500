import * as React from 'react';

import { useLocation } from 'react-router-dom';

import { JurisdictionsEnum, LodgementCaseStatusEnum, WorkspaceStatusEnum, WorkspaceTypeEnum } from '@sympli/api-gateway/enums';
import { WorkspaceParticipantApiResponse } from '@sympli/api-gateway/models';
import { PortalTarget } from '@sympli/ui-framework/components/portal';
import Logger, { PageActionEnum } from '@sympli/ui-logger';

import { Box } from 'src/components/layout';
import { usePaymentSummary } from 'src/containers/documents/reducers/paymentSummary';
import { FeatureToggleEnum } from 'src/containers/shared/auth/profile-feature-toggle/models';
import DocumentListContainer from 'src/containers/shared/document-list';
import { resolveDefaultLodgementCaseStatus } from 'src/containers/shared/helper';
import LinkedWorkspaceListContainer from 'src/containers/shared/linked-workspace-list';
import PaymentSummaryBox from 'src/containers/shared/payment-summary-box';
import { findCurrentWorkspaceParticipantSelector } from 'src/containers/shared/workspace-detail-box/selectors';
import WorkspaceDetailTimestamp from 'src/containers/shared/workspace-detail-timestamp';
import WorkspaceExpansionBox from 'src/containers/shared/workspace-expansion-box';
import { PORTAL_ID_FOR_DIRECTIONS_SUMMARY } from 'src/containers/workspace/financial/directions';
import DirectionsSummaryBoxContainer from 'src/containers/workspace/financial/directions/components/directions-summary-box';
import SettlementDirectionsListContainer from 'src/containers/workspace/financial/directions/components/settlement-directions-list';
import { SelectedSettlementDistributionsTypeEnum } from 'src/containers/workspace/financial/directions/components/settlement-directions-list/models';
import { PORTAL_ID_FOR_SETTLEMENT_DATES, PORTAL_ID_FOR_SETTLEMENT_PARTICIPANTS } from 'src/containers/workspace/financial/settlement-date';
import { resolveWorkspaceDetailLink } from 'src/containers/workspace/shared/detail/helpers';
import { useWorkspaceBasicInfo } from 'src/containers/workspace/shared/detail/reducers/workspaceBasicInfo';
import { useWorkspaceParticipants } from 'src/containers/workspace/shared/detail/reducers/workspaceParticipants';
import { useFeatureFlag, useRouterParams } from 'src/hooks';
import { WorkspaceTypeDisplayMapping } from 'src/models/workspace';
import BoxHeaderLink from 'src/pages/workspace/shared/workspace-detail-box/components/box-header-link';
import { WorkspacePageRouteParams } from '../../models';
import WorkspacePageDetailBoxContainer from '../workspace-detail-box';
import { resolveSelectedSettlementDistributionsType } from './helpers';

export default function WorkspacePageLeftPanelContainer() {
  const { pathname } = useLocation();
  const { workspaceId, participantId, titleReference } = useRouterParams<WorkspacePageRouteParams>();
  const workspaceBasicInfoState = useWorkspaceBasicInfo(workspaceId);
  const paymentSummaryState = usePaymentSummary(workspaceId, participantId);
  const workspaceParticipantsState = useWorkspaceParticipants(workspaceId);
  const isTransactionTypeEnabled: boolean = useFeatureFlag(FeatureToggleEnum.transactionTypeEnabled);

  const lodgementCaseStatusId: LodgementCaseStatusEnum = resolveDefaultLodgementCaseStatus(workspaceBasicInfoState.detail?.lodgementCases);
  const currentWorkspaceParticipant: WorkspaceParticipantApiResponse | undefined = findCurrentWorkspaceParticipantSelector({
    participantId: participantId,
    workspaceParticipants: workspaceParticipantsState
  });

  const workspaceStatusId: WorkspaceStatusEnum | undefined = workspaceBasicInfoState.detail?.workspaceStatusId;
  const workspaceTypeId: WorkspaceTypeEnum | undefined = workspaceBasicInfoState.detail?.workspaceTypeId;
  const jurisdictionId: JurisdictionsEnum | undefined = workspaceBasicInfoState.detail?.jurisdictionId;

  const disabledWorkspaceStatuses: WorkspaceStatusEnum[] = [WorkspaceStatusEnum.Abandoned, WorkspaceStatusEnum.Withdrawn];
  const isLodgementOnly = Number.isInteger(workspaceTypeId) && workspaceTypeId === WorkspaceTypeEnum.RegistrationOnly;
  const isNonLodgementOnly = Number.isInteger(workspaceTypeId) && workspaceTypeId !== WorkspaceTypeEnum.RegistrationOnly;

  const selectedSettlementDistributionsType: SelectedSettlementDistributionsTypeEnum | undefined = resolveSelectedSettlementDistributionsType(pathname);
  const portalIdForDirectionSummary = pathname.endsWith('/directions') ? PORTAL_ID_FOR_DIRECTIONS_SUMMARY : undefined;
  const isSettlementDatePage = pathname.endsWith('/settlement-date');
  const isSignDocumentsPage = pathname.endsWith('/sign-documents');
  const isSettling = workspaceStatusId === WorkspaceStatusEnum.SettlementInProgress || workspaceStatusId === WorkspaceStatusEnum.SettlementIssue;
  const portalIdForSettlementDate = isSettlementDatePage ? PORTAL_ID_FOR_SETTLEMENT_DATES : undefined;
  const portalIdForSettlementParticipants = isSettlementDatePage ? PORTAL_ID_FOR_SETTLEMENT_PARTICIPANTS : undefined;
  const showAllBoxes = Number.isInteger(workspaceStatusId) && !disabledWorkspaceStatuses.includes(workspaceStatusId!) && !isSettlementDatePage; // settlement-detail page does not show additional info

  function createTracker(section: string, workspaceId: string, participantId: string, workspaceTypeId?: WorkspaceTypeEnum) {
    return function trackOnExpandChange(expand: boolean) {
      Logger.capturePageAction(PageActionEnum.FeatureTracking, {
        feature: 'expand-box',
        logGroupId: 'workspace',
        section,
        workspaceId,
        participantId,
        workspaceType: Number.isInteger(workspaceTypeId) ? WorkspaceTypeDisplayMapping[workspaceTypeId!] : undefined,
        expand
      });
    };
  }

  const {
    //
    trackWorkspaceOverviewExpandChange,
    trackDocumentsListExpandChange,
    trackSettlementDirectionsExpandChange,
    trackDirectionsSummaryExpandChange
  } = React.useMemo(() => {
    const trackWorkspaceOverviewExpandChange = createTracker('workspace-overview', workspaceId, participantId, workspaceTypeId);
    const trackDocumentsListExpandChange = createTracker('documents-list', workspaceId, participantId, workspaceTypeId);
    const trackSettlementDirectionsExpandChange = createTracker('settlement-directions', workspaceId, participantId, workspaceTypeId);
    const trackDirectionsSummaryExpandChange = createTracker('directions-summary', workspaceId, participantId, workspaceTypeId);
    return {
      //
      trackWorkspaceOverviewExpandChange,
      trackDocumentsListExpandChange,
      trackSettlementDirectionsExpandChange,
      trackDirectionsSummaryExpandChange
    };
  }, [workspaceId, participantId, workspaceTypeId]);

  const matter = currentWorkspaceParticipant?.reference || workspaceBasicInfoState.cacheFallback?.matter;

  return (
    <>
      <WorkspaceExpansionBox
        collapsedByDefault={isSettling}
        onExpandChange={trackWorkspaceOverviewExpandChange}
        title={
          <BoxHeaderLink //
            // route parameters
            workspaceId={workspaceId}
            participantId={participantId}
            // current participant info
            matter={matter}
            lodgementCaseStatusId={lodgementCaseStatusId}
            // other
            isTopLevelOverView={pathname.endsWith('/detail')}
            linkTo={resolveWorkspaceDetailLink({ workspaceId, participantId })}
            linkIconPosition="left"
            showIcon={!pathname.endsWith('/detail')}
            // toggleable={isWorkspaceSummaryToggleable}
            // workspaceBasicInfo
            workspaceBasicInfo={workspaceBasicInfoState.detail}
            showWorkspaceStatus={workspaceBasicInfoState.status === 'resolved'}
            //
            isTransactionTypeEnabled={isTransactionTypeEnabled}
          />
        }
      >
        <WorkspacePageDetailBoxContainer
          // route parameters
          workspaceId={workspaceId}
          participantId={participantId}
          titleReference={titleReference}
          // other
          portalIdForSettlementDate={portalIdForSettlementDate}
          hideAssignedStaff={isSettlementDatePage}
        />
      </WorkspaceExpansionBox>

      {portalIdForSettlementParticipants && <PortalTarget id={portalIdForSettlementParticipants} className="tickle-box" />}

      {showAllBoxes && (
        <>
          <DocumentListContainer
            // route
            workspaceId={workspaceId}
            participantId={participantId}
            // other
            collapsedByDefault={isSignDocumentsPage || isSettling}
            onExpandChange={trackDocumentsListExpandChange}
          />
          <LinkedWorkspaceListContainer //
            workspaceId={workspaceId}
            collapsedByDefault={isSignDocumentsPage || isSettling}
          />
        </>
      )}
      {showAllBoxes && isNonLodgementOnly && (
        <>
          <SettlementDirectionsListContainer
            // route params
            workspaceId={workspaceId}
            participantId={participantId}
            // other
            collapsedByDefault={isSignDocumentsPage || isSettling}
            selectedSettlementDistributionsType={selectedSettlementDistributionsType}
            onExpandChange={trackSettlementDirectionsExpandChange}
          />
          <DirectionsSummaryBoxContainer
            // route params
            workspaceId={workspaceId}
            participantId={participantId}
            // other
            portalIdForDirectionSummary={portalIdForDirectionSummary}
            onExpandChange={trackDirectionsSummaryExpandChange}
          />
        </>
      )}
      {showAllBoxes && isLodgementOnly && (
        <Box title="Payment">
          <PaymentSummaryBox //
            // workspace basic info
            workspaceStatusId={workspaceStatusId!}
            workspaceId={workspaceId}
            participantId={participantId}
            jurisdictionId={jurisdictionId}
            // payment summary info
            lineItems={paymentSummaryState.detail?.lineItems}
            isLoading={paymentSummaryState.isLoading}
            error={paymentSummaryState.error}
          />
        </Box>
      )}
      {showAllBoxes && (
        <WorkspaceDetailTimestamp //
          isLoading={workspaceBasicInfoState.isLoading}
          // workspace basic info
          createdDate={workspaceBasicInfoState.detail?.createdDate}
          lastUpdatedDate={workspaceBasicInfoState.detail?.lastUpdatedDate}
        />
      )}
    </>
  );
}
