import * as React from 'react';

import RelinquishingTenancySection from '@sympli-mfe/document-forms-framework/components/sections/tenancy-detail/relinquishing';
import { useRootFormContext } from '@sympli-mfe/document-forms-framework/providers/root-form-context';

import { TransferRootFormContext } from '../../models';

interface Props {
  name: string;
}

function Transferors<TDocumentPartyJustification, TNameChange extends {}>({ name }: Props) {
  const { relinquishingTenancyConfig, shareTransferredTypeOptions } = useRootFormContext<TransferRootFormContext<TDocumentPartyJustification, TNameChange>>();
  return (
    <RelinquishingTenancySection //
      name={name}
      canSelectParties
      shareTransferredTypeOptions={shareTransferredTypeOptions}
      {...relinquishingTenancyConfig}
    />
  );
}

export default React.memo(Transferors);
